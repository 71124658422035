<template>
  <div class="editQuestion">
    <el-dialog
      title="修改试题"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <p class="tips">只能做简单的文字修改，不能进行公式编辑，上传图片等。</p>
      <div
        ref="quesionHtml"
        :key="newKey"
        class="quesion_html edit-scroll-style"
        contenteditable="true"
        v-html="editQuestion.question"
      ></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="restore()"> 还 原 </el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="getQuestion()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateoptquestion } from "@/core/api/exam/examLore";

export default {
  name: "EditQuestion",
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      editQuestion: {},
      indexQuestion: {},
      newKey: 1,
    };
  },
  created() {},
  methods: {
    // 还原试题
    restore() {
      this.editQuestion.question = this.editQuestion.oldQuestion;
      this.newKey = new Date().getTime();
    },
    // 初始化题目
    init(item, indexQuestion) {
      this.question = {};
      this.dialogVisible = true;
      this.editQuestion = JSON.parse(JSON.stringify(item));
      this.editQuestion.oldQuestion = JSON.parse(JSON.stringify(item)).question;
      this.indexQuestion = JSON.parse(JSON.stringify(indexQuestion));
    },
    // 更新试题
    updateoptquestion(data) {
      this.btnLoading = true;
      updateoptquestion(data)
        .then((res) => {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogVisible = false;
          let subData = {
            id: data.examPaperQuestionId,
            examQuestionOpt: res.data.data,
          };
          this.$parent.lockTraining(subData);
          // this.$store.commit("putTraining", subData);
          // this.$parent.setList(subData);
          // this.$parent.onloadView();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    getQuestion() {
      let data = {
        examPaperQuestionId: this.indexQuestion.id,
        examQuestionOptId: this.editQuestion.id,
        question: this.$refs.quesionHtml.innerHTML,
      };
      this.updateoptquestion(data);
    },
  },
};
</script>
<style scoped lang="scss">
.editQuestion {
  .tips {
    color: red;
  }
  .quesion_html {
    max-height: 400px;
  }
}
</style>
