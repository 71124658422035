<template>
  <div class="examQuestionListKn">
    <!-- 标注知识点 -->
    <template v-if="indexTypes == 'KnowledgePoints'">
      <div class="search-box">
        <div class="search-left">
          <div class="on">按拍照搜题搜索试题</div>
        </div>
        <div>
          <el-input
            v-model="inheritVal"
            placeholder="输入试题ID或者关键字"
          ></el-input>
          <el-button type="primary" class="btn-all" @click="inherit(1)">
            搜索
          </el-button>
        </div>
      </div>
      <!-- 试题列表 -->
      <!-- 已锁定 -->
      <div
        v-if="knowledgeListData.length > 0"
        v-loading="loading"
        class="question-list-box"
      >
        <div
          v-for="(v, i) in knowledgeListData"
          :key="i"
          class="question-list"
          :class="{
            'on-succ':
              v.id == indexQuestion.statusKnowledge ||
              indexQuestion.xuekeId == v.xuekeId,
          }"
        >
          <!-- {{ v.id == indexQuestion.statusKnowledge }} -->
          <div class="question-html" v-html="v.question"></div>
          <div
            class="question-btn-box"
            :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
          >
            <div class="tips">
              <p>xuekeId：{{ v.xuekeId }}</p>
              <span @click="copy(v.xuekeId)">复制</span>
            </div>
            <div :key="boxKey" class="question-btn-right">
              <el-button @click="showAwswer(v)">查看答案解析</el-button>
              <div>
                替换为原题：<el-checkbox
                  v-model="v.xuekeType"
                  @change="changeIndexQuestion(v)"
                ></el-checkbox>
              </div>
              <!-- statusReplaceKnowledge -->

              <img
                v-if="v.id != indexQuestion.statusKnowledge"
                src="@/assets/imgs/open.png"
                alt=""
                @click="addKnow(v)"
              />
              <!-- down_err.svg -->
              <template v-else>
                <img
                  v-if="indexQuestion.statusReplaceKnowledge"
                  src="@/assets/imgs/down_err.svg"
                  alt=""
                  @click="addKnow(v)"
                />
                <img v-else src="@/assets/imgs/down.png" alt="" />
              </template>

              <!--         @click="delKnow(v)" -->
            </div>
          </div>
          <answerBox
            v-if="showAwswerList.indexOf(v.id) != -1"
            :id="v.id"
          ></answerBox>
        </div>
      </div>
      <div
        v-if="trainingQuestionList.length > 0"
        v-loading="loading"
        class="question-list-box"
      >
        <div
          v-for="(v, i) in trainingQuestionList"
          :key="i"
          class="question-list"
          :class="{
            'on-succ':
              v.id == indexQuestion.statusKnowledge ||
              indexQuestion.xuekeId == v.xuekeId,
          }"
        >
          <div class="question-html" v-html="v.question"></div>
          <div
            class="question-btn-box"
            :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
          >
            <div class="tips">
              <p>xuekeId：{{ v.xuekeId }}</p>
              <span @click="copy(v.xuekeId)">复制</span>
            </div>
            <div :key="boxKey" class="question-btn-right">
              <el-button @click="showAwswer(v)">查看答案解析</el-button>
              <div>
                替换为原题：<el-checkbox
                  v-model="v.xuekeType"
                  @change="changeIndexQuestion(v)"
                ></el-checkbox>
              </div>
              <img
                v-if="v.id != indexQuestion.statusKnowledge"
                src="@/assets/imgs/open.png"
                alt=""
                @click="addKnow(v)"
              />
              <img v-else src="@/assets/imgs/down.png" alt="" />
              <!--         @click="delKnow(v)" -->
            </div>
          </div>
          <answerBox
            v-if="showAwswerList.indexOf(v.id) != -1"
            :id="v.id"
          ></answerBox>
        </div>
      </div>
      <el-divider>
        <template v-if="current != pages">加载中...</template>
        <template v-else> 没有找到更多了</template>
      </el-divider>
    </template>
    <!-- 变式训练题 -->
    <template v-if="indexTypes == 'VariantTraining'">
      <div class="search-box">
        <!-- <el-button
          v-if="indexVariationBtn == 3"
          type="primary"
          plain
          class="unload-btn"
          @click="uploadVariationDialogVisible = true"
          >自主上传试题</el-button
        > -->
        <div class="search-left">
          <div
            v-for="(v, i) in variationBtn"
            :key="i"
            :class="{ on: indexVariationBtn == v.val }"
            @click="clickIndexType(v)"
          >
            {{ v.name }}
          </div>
        </div>
        <div>
          <el-input
            v-model="inheritVal"
            placeholder="输入试题ID或者关键字"
          ></el-input>
          <el-button type="primary" class="btn-all" @click="inherit"
            >搜索</el-button
          >
        </div>
      </div>
      <!-- 条件搜索的盒子 -->
      <div v-if="indexVariationBtn == 3" class="search-item-box">
        <div class="search-item">
          <span class="search-item-title">知识点条件搜索：</span>
          <span v-for="(v, i) in knowledgeNames" :key="i" class="know-list">
            <i class="el-icon-remove" @click="delKnowList(i)"></i>
            {{ v }}
          </span>
          <span class="size-btn" @click="showChangeKnow">
            <i class="el-icon-plus"></i>添加
          </span>
        </div>
        <div class="search-item" style="padding: 4px 0 3px">
          <span>知识点交集/并集：</span>
          <el-switch
            v-model="knowSearchType"
            active-text="交集"
            inactive-text="并集"
          >
          </el-switch>
        </div>
        <div class="search-item">
          <span class="search-item-title">题型搜索条件：</span>
          <span
            v-for="(v, i) in getExamTypeList"
            :key="i"
            class="know-list type-list"
            :class="{ on: indexType == v.value }"
            @click="typeClick(v, 'indexType')"
            >{{ v.label }}</span
          >
        </div>
        <div class="search-item">
          <span class="search-item-title">试题难度：</span>
          <span
            v-for="(v, i) in difficultyList"
            :key="i"
            class="know-list difficulty-list"
            :class="{ on: indexDifficulty == v.value }"
            @click="typeClick(v, 'indexDifficulty')"
            >{{ v.label }}</span
          >
          <template v-if="indexDifficulty == 999">
            <el-input
              v-model="difficultyMin"
              class="difficulty-input"
              placeholder=""
            ></el-input>
            <hr class="hr" />
            <el-input
              v-model="difficultyMax"
              class="difficulty-input"
              placeholder=""
            ></el-input>
          </template>
          <el-button plain class="search" @click="condition()">确定</el-button>
        </div>
      </div>
      <!-- 试题列表 -->
      <!-- 已锁定的变式训练题  -->
      <div v-if="examTraining.length > 0" class="question-list-box">
        <div
          v-for="(v, i) in examTraining"
          :key="i"
          class="question-list"
          :class="{ 'on-succ': !getIndexQuestionType(v.id) }"
        >
          <div class="question-html" v-html="v.question"></div>
          <div
            class="question-btn-box"
            :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
          >
            <div class="tips">
              <p>xuekeId：{{ v.xuekeId }}</p>
              <span @click="copy(v.xuekeId)">复制</span>
              <el-button
                v-if="!getIndexQuestionType(v.id)"
                type="text"
                style="margin-left: 12px"
                @click="showEditQ(v)"
                >修改试题</el-button
              >
              <!-- <span @click="copy(v.xuekeId)">修改试题</span> -->
            </div>
            <div :key="boxKey" class="question-btn-right">
              <el-popover
                v-if="v.examList && v.examList.length > 0"
                placement="bottom"
                width="300"
                trigger="hover"
              >
                <div
                  v-for="(m, n) in v.examList"
                  :key="n"
                  class="exam-list-box"
                >
                  <span>{{ m.name }}</span>
                  <span>{{ m.examDate }}</span>
                </div>
                <el-button slot="reference">当前试题已使用</el-button>
              </el-popover>
              <el-button @click="showAwswer(v)">查看答案解析</el-button>
              <el-button @click="showQuestionModel(v)"
                >查看此题同类试题</el-button
              >
              <!-- {{ getIndexQuestionType(v.id) }} -->
              <img
                v-if="getIndexQuestionType(v.id)"
                src="@/assets/imgs/open.png"
                alt=""
                @click="lockingVariation(v)"
              />
              <img
                v-else
                src="@/assets/imgs/down.png"
                alt=""
                @click="cancelVariation(v)"
              />
            </div>
          </div>
          <answerBox
            v-if="showAwswerList.indexOf(v.id) != -1"
            :id="v.id"
          ></answerBox>
        </div>
      </div>
      <div
        v-if="trainingQuestionList.length > 0"
        v-loading="loading"
        class="question-list-box"
      >
        <div
          v-for="(v, i) in trainingQuestionList"
          :key="i"
          class="question-list"
          :class="{ 'on-succ': !getIndexQuestionType(v.id) }"
        >
          <div class="question-html" v-html="v.question"></div>
          <div
            class="question-btn-box"
            :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
          >
            <div class="tips">
              <p>xuekeId：{{ v.xuekeId }}</p>
              <span @click="copy(v.xuekeId)">复制</span>
              <el-button
                v-if="!getIndexQuestionType(v.id)"
                type="text"
                style="margin-left: 12px"
                @click="showEditQ(v)"
                >修改试题</el-button
              >
            </div>
            <div :key="boxKey" class="question-btn-right">
              <el-popover
                v-if="v.examList && v.examList.length > 0"
                placement="bottom"
                width="300"
                trigger="hover"
              >
                <div
                  v-for="(m, n) in v.examList"
                  :key="n"
                  class="exam-list-box"
                >
                  <span>{{ m.name }}</span>
                  <span>{{ m.examDate }}</span>
                </div>
                <el-button slot="reference">当前试题已使用</el-button>
              </el-popover>
              <el-button @click="showAwswer(v)">查看答案解析</el-button>
              <el-button @click="showQuestionModel(v)"
                >查看此题同类试题</el-button
              >
              <img
                v-if="getIndexQuestionType(v.id)"
                src="@/assets/imgs/open.png"
                alt=""
                @click="lockingVariation(v)"
              />
              <img
                v-else
                src="@/assets/imgs/down.png"
                alt=""
                @click="cancelVariation(v)"
              />
            </div>
          </div>
          <answerBox
            v-if="showAwswerList.indexOf(v.id) != -1"
            :id="v.id"
          ></answerBox>
        </div>
      </div>
      <el-divider>
        <template v-if="current != pages">加载中...</template>
        <template v-else> 没有找到更多了</template>
      </el-divider>
    </template>
    <!-- 分层变式训练题 -->
    <template v-if="indexTypes == 'layeredVariantTraining'">
      <div class="search-item-box layered-search-list">
        <div class="input-box">
          <el-input
            v-model="inheritVal"
            placeholder="输入试题ID或者关键字"
          ></el-input>
          <el-button type="primary" class="btn-all" @click="inherit"
            >搜索</el-button
          >
          <div class="input-up">
            <el-button
              type="primary"
              plain
              class="unload-btn"
              @click="showUpLoadFile"
              >自主上传试题</el-button
            >
          </div>
        </div>
        <div class="search-item">
          <span class="search-item-title">知识点条件搜索：</span>
          <span v-for="(v, i) in knowledgeNames" :key="i" class="know-list">
            <i class="el-icon-remove" @click="delKnowList(i)"></i>
            {{ v }}
          </span>
          <span class="size-btn" @click="showChangeKnow">
            <i class="el-icon-plus"></i>添加
          </span>
        </div>
        <div class="search-item" style="padding: 4px 0 3px">
          <span>知识点交集/并集：</span>
          <el-switch
            v-model="knowSearchType"
            active-text="交集"
            inactive-text="并集"
          >
          </el-switch>
        </div>
        <div class="search-item">
          <span class="search-item-title">题型搜索条件：</span>
          <span
            v-for="(v, i) in getExamTypeList"
            :key="i"
            class="know-list type-list"
            :class="{ on: indexType == v.value }"
            @click="typeClick(v, 'indexType')"
            >{{ v.label }}</span
          >
        </div>
        <div class="search-item">
          <span class="search-item-title">试题难度：</span>
          <span
            v-for="(v, i) in difficultyList"
            :key="i"
            class="know-list difficulty-list"
            :class="{ on: indexDifficulty == v.value }"
            @click="typeClick(v, 'indexDifficulty')"
            >{{ v.label }}</span
          >
          <template v-if="indexDifficulty == 999">
            <el-input
              v-model="difficultyMin"
              class="difficulty-input"
              placeholder=""
            ></el-input>
            <hr class="hr" />
            <el-input
              v-model="difficultyMax"
              class="difficulty-input"
              placeholder=""
            ></el-input>
          </template>
          <el-button plain class="search" @click="condition()">确定</el-button>
        </div>
      </div>
      <div class="search-box layered-search">
        <!-- <el-button
          v-if="indexVariationBtn == 3"
          type="primary"
          plain
          class="unload-btn"
          @click="uploadVariationDialogVisible = true"
          >自主上传试题</el-button
        > -->
        <div class="search-left">
          <div
            v-for="(v, i) in layeredVariationBtn"
            :key="i"
            :class="{ on: indexLayeredVariationBtn == v.val }"
            @click="clickIndexLayeredType(v)"
          >
            {{ v.name }}
          </div>
        </div>
      </div>
      <div v-if="questionExtendList.length > 0" class="question-list-box">
        <template v-for="(v, i) in questionExtendList">
          <div
            :key="i"
            class="question-list"
            :class="{ 'on-succ': !getIndexLayeredType(v.id) }"
          >
            <div class="question-html" v-html="v.question"></div>
            <div
              class="question-btn-box"
              :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
            >
              <div class="tips">
                <p>xuekeId：{{ v.xuekeId || v.id }}</p>
                <span @click="copy(v.xuekeId)">复制</span>
              </div>
              <div :key="boxKey" class="question-btn-right">
                <el-popover
                  v-if="v.extendList && v.extendList.length > 0"
                  placement="bottom"
                  width="300"
                  trigger="hover"
                >
                  <div
                    v-for="(m, n) in v.extendList"
                    :key="n"
                    class="exam-list-box"
                  >
                    <span>{{ m.name }}</span>
                    <span>{{ m.examDate }}</span>
                    <!-- {{ m.difficultyType }} -->
                    <span v-if="m.difficultyType == 1"> 较难 </span>
                    <span v-if="m.difficultyType == 2"> 中等 </span>
                    <span v-if="m.difficultyType == 3"> 较易 </span>
                  </div>
                  <el-button slot="reference">当前试题已使用</el-button>
                </el-popover>
                <el-button @click="showDiffModel(v)"
                  >分享至其他拓展层</el-button
                >
                <el-button @click="showAwswer(v)">查看答案解析</el-button>
                <el-button @click="showQuestionModel(v)"
                  >查看此题同类试题</el-button
                >
                <!--  -->
                <!-- {{ getIndexLayeredType(v.id) }} -->
                <img
                  v-if="getIndexLayeredType(v.id)"
                  src="@/assets/imgs/open.png"
                  alt=""
                  @click="trainingExtend(v, 1)"
                />
                <img
                  v-else
                  src="@/assets/imgs/down.png"
                  alt=""
                  @click="trainingExtend(v, 2)"
                />
              </div>
            </div>
            <answerBox
              v-if="showAwswerList.indexOf(v.id) != -1"
              :id="v.id"
            ></answerBox>
          </div>
        </template>
      </div>
      <div v-if="trainingQuestionList.length > 0" class="question-list-box">
        <div
          v-for="(v, i) in trainingQuestionList"
          :key="i"
          class="question-list"
          :class="{ 'on-succ': !getIndexLayeredType(v.id) }"
        >
          <div class="question-html" v-html="v.question"></div>
          <div
            class="question-btn-box"
            :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
          >
            <div class="tips">
              <p>xuekeId：{{ v.xuekeId }}</p>
              <span @click="copy(v.xuekeId)">复制</span>
            </div>
            <div :key="boxKey" class="question-btn-right">
              <el-popover
                v-if="v.extendList && v.extendList.length > 0"
                placement="bottom"
                width="300"
                trigger="hover"
              >
                <div
                  v-for="(m, n) in v.extendList"
                  :key="n"
                  class="exam-list-box"
                >
                  <span>{{ m.name }}</span>
                  <span>{{ m.examDate }}</span>
                  <!-- <template> -->
                  <span v-if="m.difficultyType == 1"> 较难 </span>
                  <span v-if="m.difficultyType == 2"> 中等 </span>
                  <span v-if="m.difficultyType == 3"> 较易 </span>
                  <!-- </template> -->
                </div>
                <el-button slot="reference">当前试题已使用</el-button>
              </el-popover>
              <el-button @click="showDiffModel(v)">分享至其他拓展层</el-button>
              <el-button @click="showAwswer(v)">查看答案解析</el-button>
              <el-button @click="showQuestionModel(v)"
                >查看此题同类试题</el-button
              >
              <!--  -->
              <!-- {{ getIndexLayeredType(v.id) }} -->
              <img
                v-if="getIndexLayeredType(v.id)"
                src="@/assets/imgs/open.png"
                alt=""
                @click="trainingExtend(v, 1)"
              />
              <img
                v-else
                src="@/assets/imgs/down.png"
                alt=""
                @click="trainingExtend(v, 2)"
              />
            </div>
          </div>
          <answerBox
            v-if="showAwswerList.indexOf(v.id) != -1"
            :id="v.id"
          ></answerBox>
        </div>
      </div>
      <el-divider>
        <template v-if="current != pages">加载中...</template>
        <template v-else> 没有找到更多了</template>
      </el-divider>
    </template>
    <!-- 二叉树的弹出层 -->
    <el-dialog
      v-dialogDrag
      title="批量选择知识点"
      :visible.sync="twoTree"
      width="50%"
      :modal="false"
    >
      <div class="two-tree-box">
        <div>
          <el-tree
            v-if="twoTreetype"
            ref="tree"
            :key="newKeys"
            :data="knowledgeAllList"
            show-checkbox
            node-key="number"
            :default-checked-keys="loreTureArrid"
            :default-expanded-keys="twoTreeArray"
            :props="defaultProps"
            @check="elTreeChange"
          >
          </el-tree>
        </div>
        <div style="width: 300px">
          <el-input
            v-model="twoTreeVal"
            placeholder="请输入内容"
            class="input-with-select"
            @input="searchLore"
          >
            <el-button
              slot="append"
              class="search-btn"
              icon="el-icon-search"
              @click="searchLore"
            ></el-button>
          </el-input>
          <el-checkbox-group
            v-model="loreTureArrid"
            style="width: 200px; margin-top: 20px"
          >
            <template v-for="(v, i) in TreeArray">
              <el-checkbox :key="i" :label="v.number">{{ v.name }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="twoTree = false">取 消</el-button>
        <el-button class="btn-all" type="primary" @click="isOkTree"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 同类试题弹出层 -->
    <el-dialog title="同类题" :visible.sync="questionListModel" width="50%">
      <div
        v-for="(v, i) in similarQuestionsList"
        :key="i"
        v-loading="loading"
        class="question-list"
      >
        <div class="question-html" v-html="v.question"></div>
        <div
          v-if="indexTypes == 'VariantTraining'"
          class="question-btn-box"
          :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
        >
          <div class="tips">
            <p>xuekeId：{{ v.xuekeId }}</p>
            <span @click="copy(v.xuekeId)">复制</span>
          </div>
          <div :key="boxKey" class="question-btn-right">
            <!-- <el-button @click="showAwswer(v)">查看答案解析</el-button> -->
            <img
              v-if="getIndexQuestionType(v.id)"
              src="@/assets/imgs/open.png"
              alt=""
              @click="lockingVariation(v)"
            />
            <img
              v-else
              src="@/assets/imgs/down.png"
              alt=""
              @click="cancelVariation(v)"
            />
          </div>
        </div>
        <div
          v-if="indexTypes == 'layeredVariantTraining'"
          class="question-btn-box"
          :class="{ 'show-btn': showAwswerList.indexOf(v.id) != -1 }"
        >
          <div class="tips">
            <p>xuekeId：{{ v.xuekeId || v.id }}</p>
            <span @click="copy(v.xuekeId)">复制</span>
          </div>
          <div :key="boxKey" class="question-btn-right">
            <el-popover
              v-if="v.extendList && v.extendList.length > 0"
              placement="bottom"
              width="300"
              trigger="hover"
            >
              <div
                v-for="(m, n) in v.extendList"
                :key="n"
                class="exam-list-box"
              >
                <span>{{ m.name }}</span>
                <span>{{ m.examDate }}</span>
                <!-- {{ m.difficultyType }} -->
                <span v-if="m.difficultyType == 1"> 较难 </span>
                <span v-if="m.difficultyType == 2"> 中等 </span>
                <span v-if="m.difficultyType == 3"> 较易 </span>
              </div>
              <el-button slot="reference">当前试题已使用</el-button>
            </el-popover>
            <el-button @click="showDiffModel(v)">分享至其他拓展层</el-button>
            <el-button @click="showAwswer(v)">查看答案解析</el-button>
            <!-- <el-button @click="showQuestionModel(v)"
              >查看此题同类试题</el-button
            > -->
            <!--  -->
            <!-- {{ getIndexLayeredType(v.id) }} -->
            <img
              v-if="getIndexLayeredType(v.id)"
              src="@/assets/imgs/open.png"
              alt=""
              @click="trainingExtend(v, 1)"
            />
            <img
              v-else
              src="@/assets/imgs/down.png"
              alt=""
              @click="trainingExtend(v, 2)"
            />
          </div>
        </div>
        <!-- <answer v-if="showAwswerList.indexOf(v.id) != -1" :id="v.id"></answer> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="questionListModel = false">取 消</el-button>
        <el-button
          class="btn-all"
          type="primary"
          @click="questionListModel = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 上传变式训练题弹窗 -->
    <el-dialog
      class="uploadVariationDialog"
      title="上传试题"
      :visible.sync="uploadVariation"
      width="650px"
    >
      <el-tabs v-model="uploadType" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="变式训练题" name="1">
          <div v-if="uploadType == 1" class="upload-tips">
            通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
              type="primary"
              href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%8F%98%E5%BC%8F%E8%AE%AD%E7%BB%83%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
              >下载 Word 模板<span class="el-icon-download"></span
            ></el-link>
            <br />注意： <br />1、标签【题号】、【套号】、【试题】为必填。
            <br />2、标签【题号】是一道题的开始，即一道题的第一个标签。务必与切题时设置的题号一致。如果是材料题，请填写大题题号，不要填写子题题号。
            <br />3、标签【套号】填写范围为 1-5。表示该题为第几套变式题。
            <br />4、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
            <br />5、上传会根据【题号】、【套号】覆盖已锁定的变式题。
          </div>
        </el-tab-pane>
        <el-tab-pane label="分层拓展题" name="2">
          <div v-if="uploadType == 2" class="upload-tips">
            通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
              type="primary"
              href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%88%86%E5%B1%82%E5%8F%98%E5%BC%8F%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
              >下载 Word 模板<span class="el-icon-download"></span
            ></el-link>
            <br />注意： <br />1、标签【题号】、【难度】、【试题】为必填。
            <br />2、标签【题号】是一道题的开始，即一道题的第一个标签。务必与切题时设置的题号一致。如果是材料题，请填写大题题号，不要填写子题题号。
            <br />3、标签【难度】填写范围为 1-3，分别代表 较难 、 中等 、 较易
            。表示该题的分层难度。
            <br />4、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
            <br />5、上传会根据【题号】、【难度】增加该题该难度的分层变式题。
          </div>
        </el-tab-pane>
        <el-tab-pane label="图片搜索试题" name="3">
          根据上传的图片搜索试题，不会绑定至小题，支持上传文件类型：.png,jpg,jpeg,gif
        </el-tab-pane>
      </el-tabs>

      <el-upload
        ref="uploadVariationFile"
        class="uploadVariation"
        :action="uploadVariationURL"
        :headers="uploadVariationHeaders"
        :data="uploadVariationData"
        :auto-upload="false"
        :accept="uploadType == 3 ? '.png,jpg,jpeg,gif' : '.docx'"
        :before-upload="handleUploadVariationBefore"
        :on-progress="handleUploadVariationOnProgress"
        :on-success="handleUploadVariationOnSuccess"
        :on-error="handleUploadVariationOnError"
        :limit="1"
        drag
      >
        <!--      :show-file-list="false" -->
        <div class="upload-text">将文件拖放到此处或点击上传</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadVariation = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="submitUpload()">
          上 传
        </el-button>
      </span>
    </el-dialog>
    <!-- 上传分层拓展题弹窗 -->
    <el-dialog
      class="uploadVariationDialog"
      :title="titleDialog"
      :visible.sync="uploadLayerVariationDialogVisible"
      width="650px"
    >
      <div class="upload-tips">
        通过上传 Word 文件上传变式题，可一次上传多道多套题。<el-link
          type="primary"
          href="https://static.wtjy.com/exam/paper/template/%E8%87%AA%E4%B8%BB%E4%B8%8A%E4%BC%A0%E5%88%86%E5%B1%82%E5%8F%98%E5%BC%8F%E9%A2%98%E6%A8%A1%E6%9D%BF.docx"
          >下载 Word 模板<span class="el-icon-download"></span
        ></el-link>
        <br />注意： <br />1、标签【题号】、【难度】、【试题】为必填。
        <br />2、标签【题号】是一道题的开始，即一道题的第一个标签。务必与切题时设置的题号一致。如果是材料题，请填写大题题号，不要填写子题题号。
        <br />3、标签【难度】填写范围为 1-3，分别代表 较难 、 中等 、 较易
        。表示该题的分层难度。
        <br />4、模板里只提供了一道题的标签，增加试题只需要换行增加一套这些标签即可。
        <br />5、上传会根据【题号】、【难度】增加该题该难度的分层变式题。
      </div>
      <el-upload
        class="uploadVariation"
        :action="uploadLayerVariationURL"
        :headers="uploadVariationHeaders"
        :data="uploadVariationData"
        accept=".docx"
        :show-file-list="false"
        :before-upload="handleUploadVariationBefore"
        :on-progress="handleUploadVariationOnProgress"
        :on-success="handleUploadLayerVariationOnSuccess"
        :on-error="handleUploadVariationOnError"
        drag
      >
        <div class="upload-text">将文件拖放到此处或点击上传</div>
      </el-upload>
    </el-dialog>
    <!-- 分享拓展层 -->
    <el-dialog
      title="分享至其他拓展层"
      :visible.sync="dialogVisibleDiff"
      width="300px"
    >
      <!-- {{ layeredVaTypes }} -->
      <!-- class="model-check" -->
      <el-checkbox-group
        v-model="diffCheckboxGroup"
        class="model-check"
        @change="checkClick"
      >
        <template v-for="(v, i) in layeredList">
          <el-checkbox
            v-if="layeredVaTypes != v.val"
            :key="i"
            :label="v.val"
            border
            >{{ v.name }}</el-checkbox
          ></template
        >

        <!-- <el-checkbox label="备选项2" border></el-checkbox> -->
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDiff = false">取 消</el-button>
        <el-button
          class="btn-all"
          type="primary"
          :loading="modelLoading"
          @click="submitLayer"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <editQuestion ref="editQuestion"></editQuestion>
  </div>
</template>

<script>
const variationBtn = [
  {
    name: "按举一反三搜索试题",
    val: 1,
  },
  {
    name: "按拍照搜题搜索试题",
    val: 2,
  },
  {
    name: "按条件搜索试题",
    val: 3,
  },
  {
    name: "自主上传试题",
    val: 4,
  },
];
const layeredVariationBtn = [
  {
    name: "按以上条件搜索试题",
    val: 1,
  },
  {
    name: "显示拍照搜题搜索结果",
    val: 2,
  },
  {
    name: "显示原举一反三搜索结果",
    val: 3,
  },
  // {
  //   name: "自主上传试题",
  //   val: 4
  // }
];
const layeredList = [
  {
    name: "较易拓展题",
    val: 3,
  },
  {
    name: "中等拓展题",
    val: 2,
  },
  {
    name: "较难拓展题",
    val: 1,
  },
];
// import {  } from "@/core/util/constdata";
import {
  STEP_MARKKNOWLEDGEPOINT,
  STEP_MATCHVARIATIONQUESTION,
  STEP_FINISHALL,
  difficultyList,
} from "@/core/util/constdata";
import { setExamPaperStatusStep } from "@/core/api/exam/exampaper";
// import {  } from "@/core/util/constdata";

import { answer } from "@/core/api/resource/knowledgePointsPaper";
import {
  trainingList,
  getList,
  // putObj,
  getRecommend,
  putPaperQuestionObj,
  examRandom,
  lockTraining,
  unlockTraining,
  trainings,
  trainingExtend,
  exampaperquestionExtend,
  postTrainingList,
} from "@/core/api/exam/examLore";
import { mapGetters } from "vuex";
import answerBox from "@/components/answer";
import editQuestion from "./editQuestion";
import { getStore } from "@/core/util/store";
export default {
  name: "ExamQuestionListKn",
  components: {
    answerBox,
    editQuestion,
  },
  props: {
    indexTypes: String,
    layeredTypes: String,
  },
  data() {
    return {
      uploadType: "0",
      titleDialog: "自主上传",
      uploadVariation: false,
      knowledgeNames: [],
      //知识点已锁定列表
      knowledgeListData: [],
      questionListModel: false,
      // 其他拓展层
      dialogVisibleDiff: false,
      modelLoading: false,
      layeredList: layeredList,
      diffCheckboxGroup: [],
      similarQuestionsList: [],
      variationBtn: variationBtn,
      layeredVariationBtn: layeredVariationBtn,
      indexVariationBtn: 1,
      indexLayeredVariationBtn: 1,
      difficultyList: JSON.parse(JSON.stringify(difficultyList)),
      input: "",
      checked: false,
      loading: false,
      oldQuestionId: 0,
      trainingQuestionList: [],
      showAwswerList: [],
      indexQuestion: {},
      inheritVal: "",
      boxKey: 1,
      knowSearchType: false,
      indexType: 0,
      indexDifficulty: 0,
      difficultyMin: 0.1,
      difficultyMax: 0.9,
      // 知识点弹出层 start
      twoTree: false,
      twoTreetype: true,
      defaultProps: {
        children: "child",
        label: "name",
      },
      loreTureArrid: [],
      TreeArray: [],
      twoTreeArray: [],
      newKeys: 1,
      twoTreeVal: "",
      knowledgeAllList: [],
      loreArr: [],
      //知识点弹出层 end

      // 上传变式题
      uploadVariationDialogVisible: false,
      uploadVariationURL: "/exam/exampaper/uploadexamquestionopt",
      uploadVariationHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      uploadVariationData: {},
      // 上传分层拓展题
      uploadLayerVariationDialogVisible: false,
      uploadLayerVariationURL: "/exam/exampaper/uploadexamquestionextend",
      //已锁定的变式训练题
      examTraining: [],
      layeredVaTypes: 3,
      pages: 0,
      current: 1,
      reqTime: 1,
      questionExtendList: [],
    };
  },
  computed: {
    ...mapGetters([
      "getExamPaper",
      "getIndexQuestion",
      "getExamQuestion",
      "getExamTypeList",
      "getPaperKnowList",
      "getPaperTreeKnowList",
    ]),
  },
  watch: {
    layeredTypes() {
      // this.oldDataList = [];
      this.current = 1;
      this.trainingQuestionList = [];
      if (this.layeredTypes == "difficultQuestions") {
        this.layeredVaTypes = 1;
        this.indexDifficulty = 0.1;
      }
      if (this.layeredTypes == "commonlyQuestions") {
        this.layeredVaTypes = 2;
        this.indexDifficulty = 0.5;
      }
      if (this.layeredTypes == "simpleQuestions") {
        this.layeredVaTypes = 3;
        this.indexDifficulty = 0.9;
      }
      this.oldDataList = [];
      this.current = 1;
      this.onloadView();
    },
    indexTypes() {
      // console.log("测试1111");
      this.oldDataList = [];
      this.current = 1;
      this.pages = 0;
      this.onloadView();
    },
    indexVariationBtn() {
      this.oldDataList = [];
      this.current = 1;
      this.pages = 0;
      this.getSearchList();
    },
    getPaperKnowList: {
      deep: true,
      handler() {
        this.knowledgeAllList = this.getPaperTreeKnowList;
        this.loreArr = this.getPaperKnowList;
        // console.log(this.loreArr);
      },
    },
    // 监听当前题在试题列表中是否更新了数据
    getExamQuestion: {
      deep: true,
      handler() {
        this.setStatusStep();
        this.getExamQuestion.forEach((index) => {
          if (this.indexQuestion.examQuestionId == index.examQuestionId) {
            //如果xuekeid不同  就是更新了（是否为原题）
            // console.log(this.indexQuestion.xuekeId);
            // console.log(index.xuekeId);
            if (index.xuekeId != this.indexQuestion.xuekeId) {
              this.indexQuestion = Object.assign({}, index);
              this.setTraningList();
            }
            this.indexQuestion = Object.assign({}, index);
            setTimeout(() => {
              this.boxKey = new Date().getTime();
            }, 200);
            // console.log(this.indexQuestion);
            //如果是切换了试题类型
            if (this.oldQuestionType != this.indexQuestion.type) {
              this.oldQuestionType = this.indexQuestion.type;
              this.onloadView();
            }
            this.setKnowList();
          }
        });
      },
    },
    // 获取当前项试题
    getIndexQuestion: {
      immediate: true,
      handler() {
        if (this.oldQuestionId != this.getIndexQuestion.id) {
          if (this.indexVariationBtn == 4) {
            this.indexVariationBtn = 1;
          }
          // 回到顶部
          this.backTop();
          this.oldQuestionId = this.getIndexQuestion.examQuestionId;
          this.oldQuestionType = this.getIndexQuestion.type;
          this.indexQuestion = Object.assign({}, this.getIndexQuestion);
          this.indexType = this.getIndexQuestion.type;
          this.trainingQuestionList = [];
          this.examTraining = [];
          this.questionExtendList = [];
          // this.trainingList();
          // console.log("触发了");
          this.knowledgeListData = [];
          // console.log(this.indexQuestion);
          if (
            this.indexTypes == "KnowledgePoints" &&
            this.indexQuestion.statusKnowledge > 1
          ) {
            this.getQuestion(this.indexQuestion.statusKnowledge);
          }
          if (this.indexQuestion.id) {
            this.setKnowList();
            this.onloadView();
          }
        }
      },
    },
    //标注知识点
    knowledgeListData: {
      deep: true,
      handler: function () {
        if (
          this.knowledgeListData.length > 0 &&
          this.trainingQuestionList.length > 0
        ) {
          this.trainingQuestionList.map((index, arr) => {
            if (
              this.knowledgeListData.filter((item) => item.id == index.id)
                .length > 0
            ) {
              this.trainingQuestionList.splice(arr, 1);
            }
          });
        }
        this.trainingSet();
        // this.setState();
      },
    },
    examTraining: {
      deep: true,
      handler: function () {
        if (
          this.examTraining.length > 0 &&
          this.trainingQuestionList.length > 0
        ) {
          this.trainingQuestionList.map((index, arr) => {
            if (
              this.examTraining.filter((item) => item.id == index.id).length > 0
            ) {
              this.trainingQuestionList.splice(arr, 1);
            }
          });
        }
        // this.setState();
      },
    },
    trainingQuestionList: {
      deep: true,
      handler: function () {
        //过滤下相同的试题
        this.trainingQuestionList.map((index) => {
          if (
            this.trainingQuestionList.filter((i) => i.xuekeId == index.xuekeId)
              .length > 1
          ) {
            this.trainingQuestionList = this.trainingQuestionList.filter(
              (item) => item.id != index.id
            );
          }
        });

        //标注知识点的时候
        if (this.indexTypes == "KnowledgePoints") {
          // console.log(this.knowledgeListData);
          if (
            this.knowledgeListData.length > 0 &&
            this.trainingQuestionList.length > 0
          ) {
            this.trainingQuestionList.map((index, arr) => {
              if (
                this.knowledgeListData.filter((item) => item.id == index.id)
                  .length > 0
              ) {
                // console.log(
                //   this.knowledgeListData.filter((item) => item.id == index.id)
                //     .length > 0
                // );
                this.trainingQuestionList.splice(arr, 1);
              }
            });
          }
          this.trainingSet();
        }
        //变式训练题的时候
        if (this.indexTypes == "VariantTraining") {
          if (
            this.examTraining.length > 0 &&
            this.trainingQuestionList.length > 0
          ) {
            this.trainingQuestionList.map((index, arr) => {
              if (
                this.examTraining.filter((item) => item.id == index.id).length >
                0
              ) {
                this.trainingQuestionList.splice(arr, 1);
              }
            });
          }
          this.trainingSet();
        }
        //分层变式训练题的时候
        if (this.indexTypes == "layeredVariantTraining") {
          // console.log(1111111);
          if (
            this.questionExtendList.length > 0 &&
            this.trainingQuestionList.length > 0
          ) {
            this.trainingQuestionList.map((index, arr) => {
              if (
                this.questionExtendList.filter((item) => item.id == index.id)
                  .length > 0
              ) {
                this.trainingQuestionList.splice(arr, 1);
              }
            });
          }
        }
        this.trainingSet();
      },
    },
    // if (this.indexTypes == "VariantTraining") {
    //     this.getSearchList();
    //   }
    //   if (this.indexTypes == "layeredVariantTraining") {
    //     this.getSearchLayered();
    //   }
    questionExtendList: {
      deep: true,
      handler: function () {
        if (
          this.questionExtendList.length > 0 &&
          this.trainingQuestionList.length > 0
        ) {
          this.trainingQuestionList.map((index, arr) => {
            if (
              this.questionExtendList.filter((item) => item.id == index.id)
                .length > 0
            ) {
              this.trainingQuestionList.splice(arr, 1);
            }
          });
        }
      },
    },
  },
  created() {
    // trainingList
    // this.trainingList();
    this.setDifficultyList();
    this.uploadVariationData = { examPaperId: this.$route.query.id };
    // this.onloadView();
  },
  mounted() {
    window.addEventListener("scroll", this.disposeScroll); //监听滚动条
  },
  beforeDestroy() {
    //离开
    window.removeEventListener("scroll", this.disposeScroll); // 离开是后清除监听
  },
  methods: {
    handleClick() {
      // console.log(this.indexQuestion);
      if (this.uploadType == "1") {
        this.uploadVariationURL = "/exam/exampaper/uploadexamquestionopt";
      }
      if (this.uploadType == "2") {
        this.uploadVariationURL = "/exam/exampaper/uploadexamquestionextend";
      }
      if (this.uploadType == "3") {
        this.uploadVariationURL =
          "/exam/examquestion/searchimg/training/" +
          this.indexQuestion.examQuestionId;
      }

      // console.log(val);
    },
    submitUpload() {
      this.$refs.uploadVariationFile.submit();
    },
    showUpLoadFile() {
      this.uploadVariationURL = "/exam/exampaper/uploadexamquestionextend";
      this.titleDialog = "自主上传分层拓展题";
      this.uploadVariation = true;
      this.uploadType = "2";
    },
    // 回到顶部
    backTop() {
      let timer = setInterval(function () {
        var scrollTop = Math.floor(
          document.body.scrollTop ||
            document.documentElement.scrollTop ||
            window.pageYOffset
        );
        var speed = Math.floor(scrollTop / 4);
        if (scrollTop <= 10) {
          document.documentElement.scrollTop = document.body.scrollTop = 0;
        } else {
          document.documentElement.scrollTop = document.body.scrollTop =
            scrollTop - speed;
        }
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    },
    setStatusStep() {
      // 检查是否所有的题都标注了知识点
      let hasAllQuestionsMark = true,
        hasPartQuestionsMark = false;
      //检查所有题是否都匹配了变式训练题
      let isOk = true;
      for (let i = 0; i < this.getExamQuestion.length; i++) {
        let que = this.getExamQuestion[i];
        if (que.examQuestionOpt1 == 0) {
          isOk = false;
        }
        if (!que.knowledgeNames || que.knowledgeNames.length === 0) {
          hasAllQuestionsMark = false;
        } else {
          hasPartQuestionsMark = true;
        }
      }
      // 标注知识点的话
      if (this.indexTypes == "KnowledgePoints") {
        if (hasAllQuestionsMark) {
          let data = {
            examPaperId: this.$route.query.id,
            statusStep: STEP_MATCHVARIATIONQUESTION,
            statusAnswer: 1,
          };
          setExamPaperStatusStep(data);
        } else if (hasPartQuestionsMark) {
          let data = {
            examPaperId: this.$route.query.id,
            statusStep: STEP_MARKKNOWLEDGEPOINT,
            statusAnswer: 2,
          };
          setExamPaperStatusStep(data);
        } else {
          let data = {
            examPaperId: this.$route.query.id,
            statusStep: STEP_MARKKNOWLEDGEPOINT,
            statusAnswer: 0,
          };
          setExamPaperStatusStep(data).then(() => {
            // console.log(res);
          });
        }
      }
      //变式训练题的话提交
      if (this.indexTypes == "VariantTraining") {
        if (isOk) {
          if (this.getExamPaper.statusStep < STEP_FINISHALL) {
            let data = {
              examPaperId: this.$route.query.id,
              statusStep: STEP_FINISHALL,
            };
            setExamPaperStatusStep(data).then(() => {});
          }
        }
      }
    },
    submitLayer() {
      if (this.diffCheckboxGroup.length == 0) {
        this.$message({
          message: "请选择难度！",
          type: "warning",
        });
        return;
      }
      let data = {
        examQuestionOpt: this.indexLayerObj.id,
        examQuestionId: this.indexQuestion.examQuestionId,
        examPaperId: this.indexQuestion.examPaperId,
        type: 1,
        difficultyType: this.diffCheckboxGroup[0],
      };
      this.modelLoading = true;
      trainingExtend(data)
        .then(() => {
          this.$message({
            message: "分享成功！",
            type: "success",
          });
          this.dialogVisibleDiff = false;
          this.modelLoading = false;
          this.$store.commit("changeQuestion", data);
        })
        .catch(() => {
          this.dialogVisibleDiff = false;
          this.modelLoading = false;
        });
    },
    checkClick(e) {
      // console.log(e);
      // console.log(this.diffCheckboxGroup);
      if (e.length > 0) {
        let arr = [];
        arr.push(e[e.length - 1]);
        this.diffCheckboxGroup = arr;
      } else {
        this.diffCheckboxGroup = [];
      }
    },
    showDiffModel(v) {
      // console.log(v);
      this.indexLayerObj = v;
      this.dialogVisibleDiff = true;
    },
    //当前试题锁定的分层（所有）包含不同难度的变式训练题
    exampaperquestionExtend() {
      exampaperquestionExtend(this.indexQuestion.id).then((res) => {
        // console.log(res);
        //  v-if="v.difficultyType == layeredVaTypes"
        let arr = [];
        res.data.data.forEach((index) => {
          if (index.difficultyType == this.layeredVaTypes) {
            arr.push(index);
          }
        });
        this.questionExtendList = arr;
        //  = res.data.data;
      });
    },
    //上拉加载
    disposeScroll() {
      if (this.current == this.pages) {
        return;
      } // 如果当前大于总页数，也就是最后一页，那么就不不触发了
      let time = new Date().getTime();
      if (time - this.reqTime < 2000) {
        return;
      }
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0; // 获取滚动条垂直的位置
      if (document.body.clientHeight - top - window.innerHeight == 0) {
        this.current++;
        this.reqTime = time;
        // console.log(this.current);
        this.onloadView();
        // this.initSeaechData();
        //如果滚动条的位置加上窗口的高度大于可见的窗口可见的高度，那么也就是滚动条到低了
        // this.$emit('update') // 执行update方法
      }
    },
    //获取当前试题是否是当前锁定项
    getIndexLayeredType(id) {
      let type = true;
      // console.log(this.layeredVaTypes);
      this.indexQuestion.examQuestionExtendList.forEach((index) => {
        // console.log(index.examQuestionOpt == id);
        // console.log(index.difficultyType == this.layeredVaTypes);
        if (
          index.examQuestionOpt == id &&
          index.difficultyType == this.layeredVaTypes
        ) {
          type = false;
        }
      });
      // console.log(type);
      return type;
    },
    //锁定  取消分层变式训练题
    trainingExtend(val, type) {
      // difficultyType	分层区间（难度类型）：较难:1 /中等:2/ 较易:3		false
      // examPaperId	试卷ID		false
      // examQuestionId	试题ID		false
      // examQuestionOpt	变式训练题ID		false
      // id			false
      // type 区分新增、删除。1：新增，2：删除
      let data = {
        examQuestionOpt: val.id,
        examQuestionId: this.indexQuestion.examQuestionId,
        examPaperId: this.indexQuestion.examPaperId,
        type: type,
        difficultyType: this.layeredVaTypes,
      };
      trainingExtend(data).then(() => {
        this.$store.commit("changeQuestion", data);
        this.setExtendList(data);
        // console.log(res);
      });
      // console.log(data);
    },
    //分层变式训练
    clickIndexLayeredType(v) {
      this.indexLayeredVariationBtn = v.val;
      // this.oldDataList = [];
      this.current = 1;
      this.getSearchLayered();
    },
    getSearchLayered() {
      if (this.indexQuestion.examQuestionExtendList.length > 0) {
        this.exampaperquestionExtend();
      }
      if (this.indexLayeredVariationBtn == 1) {
        this.condition();
      }
      if (this.indexLayeredVariationBtn == 2) {
        this.trainingList();
      }
      if (this.indexLayeredVariationBtn == 3) {
        this.getIdList();
      }
    },
    //自主上传变式训练题 start
    getIndexQuestionType(id) {
      let str = "examQuestionOpt";
      let type = true;
      for (let i = 1; i < 6; i++) {
        let newStr = str + i;
        if (this.indexQuestion[newStr] == id) {
          // console.log("有111111111");
          type = false;
        }
      }
      // console.log(id);
      // console.log(type);
      return type;
    },
    handleUploadVariationBefore(file) {
      if (!file.name.endsWith(".docx") && this.uploadType != 3) {
        this.$message.error("只能上传 .docx 格式的 Word 文件！");
        return false;
      }
      if (
        this.uploadType == 3 &&
        file.name.endsWith(".png") &&
        file.name.endsWith(".jpg") &&
        file.name.endsWith(".jpeg") &&
        file.name.endsWith(".gif")
      ) {
        this.$message.error("只能上传.png,jpg,jpeg,gif格式文件！");
        return false;
      }
      let is30M = file.size / 1024 / 1024 <= 30;
      if (!is30M) {
        this.$message.error("文件大小不能超过 30 MB！");
        return false;
      }
      return true;
    },
    handleUploadVariationOnProgress() {
      this.loading = true;
    },
    handleUploadVariationOnSuccess(response, file, fileList) {
      // console.log(response);
      this.loading = false;
      if (response.code == 1) {
        this.$message({
          showClose: true,
          message: response.msg,
          type: "error",
        });
        return;
      }

      if (this.uploadType == 3) {
        // console.log(response);
        if (response.data.length == 0) {
          this.$message({
            showClose: true,
            message: "没有找到试题，请重试！",
            type: "warning",
          });
        } else {
          this.$message({
            showClose: true,
            message: "搜索成功，已为您添加至第一条！",
            type: "success",
          });
          this.handleUploadVariationFileRemove(fileList);
          this.trainingQuestionList = response.data.concat(
            this.trainingQuestionList
          );
          this.uploadVariation = false;
        }
      } else {
        this.handleUploadVariationFileRemove(fileList);
        this.uploadVariation = false;
        this.$emit("getNewView");
      }

      // this.examList();
    },
    handleUploadLayerVariationOnSuccess(response, file, fileList) {
      // console.log(response);
      this.loading = false;
      this.handleUploadVariationFileRemove(fileList);
      this.uploadLayerVariationDialogVisible = false;
      this.$emit("getNewView");
      // this.examList();
    },
    handleUploadVariationOnError(err, file, fileList) {
      // console.log(err);
      this.loading = false;
      this.handleUploadVariationFileRemove(fileList);
    },
    handleUploadVariationFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    // 自主上传变式训练题 end
    // 获取同类题列表
    showQuestionModel(v) {
      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      this.btnDisabled = true;
      getRecommend(
        this.indexQuestion.examQuestionId,
        v.recommendXuekeId,
        this.getExamPaper.id
      )
        .then((res) => {
          this.questionListModel = true;
          this.btnDisabled = false;
          if (res.data.data && res.data.data.length == 0) {
            this.$message({
              message: "未找到变式题",
              type: "warning",
            });
          } else {
            this.similarQuestionsList = res.data.data;
          }
        })
        .catch(() => {
          this.pages = this.current;
          this.btnDisabled = false;
        });
    },
    // 变式训练题取消
    cancelVariation(val) {
      let data = {
        examQuestionOpt: val.id,
      };
      this.unlockTraining(data);
      // console.log(val);
    },
    //变式训练题添加
    lockingVariation(val) {
      // console.log(val);
      // console.log(this.indexQuestion);
      let data = {
        examQuestionOpt: val.id,
      };
      data.statusReplaceTraining = 0;
      this.lockTraining(data);
      // examQuestionOpt
    },
    //已锁定的变式训练题  有人为操作过才调用这个接口
    getTrainings() {
      this.examTraining = [];
      trainings(this.indexQuestion.id).then((res) => {
        // console.log(res);
        this.examTraining = res.data.data;
        this.examTraining.forEach((index) => {
          if (!index.examList) {
            index.examList = [];
          }
        });
        this.$nextTick(() => {
          window.MathJyeLoad.LayOut(document.body);
        });
      });
    },
    //页面加载
    onloadView() {
      // console.log("通过初始方法触发");
      // this.trainingQuestionList = [];
      if (this.indexTypes == "KnowledgePoints") {
        this.trainingList();
      }
      if (this.indexTypes == "VariantTraining") {
        this.getSearchList();
      }
      if (this.indexTypes == "layeredVariantTraining") {
        this.getSearchLayered();
      }
    },
    // 条件搜索
    condition() {
      let data = {
        knowledgeId: this.knowledgeIds.join("#"),
        questionId: this.indexQuestion.examQuestionId,
        beginIndex: this.current,
        examPaperId: this.getExamPaper.id,
        quesDiff: this.indexDifficulty,
        quesType: this.indexType,
      };
      if (this.knowSearchType) {
        data.knowledgeGatherType = 2;
      } else {
        data.knowledgeGatherType = 0;
      }
      examRandom(data)
        .then((res) => {
          // this.loading = false;
          // this.trainingQuestionList = res.data.data;
          this.setDataList(res.data.data);
        })
        .catch(() => {
          this.pages = this.current;
          // this.loading = false;
        });
    },
    //修改试题信息
    putPaperQuestionObj(data) {
      data.id = this.indexQuestion.id;
      putPaperQuestionObj(data).then(() => {
        this.$store.commit("changeIdQuestion", data);
        setTimeout(() => {
          this.trainingSet();
        }, 100);
      });
    },
    getSearchList() {
      // console.log("通过搜索方法触发");
      // this.trainingQuestionList = [];
      // this.loading = true;
      // this.oldDataList = [];
      if (this.indexQuestion.statusTraining == 1) {
        this.getTrainings();
      }
      if (this.indexVariationBtn == 1) {
        this.getIdList();
      }
      if (this.indexVariationBtn == 2) {
        this.trainingList();
      }
      if (this.indexVariationBtn == 3) {
        this.condition();
      }
      if (this.indexVariationBtn == 4) {
        // this.condition();
        this.titleDialog = "自主上传";
        this.uploadVariation = true;
        this.uploadVariationURL = "/exam/exampaper/uploadexamquestionopt";
        this.uploadType = "1";
        // this.uploadVariationDialogVisible = true;
      }
    },
    //举一反三
    getIdList() {
      this.trainingQuestionList = [];
      // console.log(this.indexQuestion);
      let { examQuestionId, recommendXuekeId } = this.indexQuestion;
      getRecommend(examQuestionId, recommendXuekeId, this.getExamPaper.id)
        .then((res) => {
          // this.loading = false;  this.current
          this.pages = 1;
          this.current = 1;
          if (res.data.data && res.data.data.length == 0) {
            this.$message({
              message: "未找到变式题",
              type: "warning",
            });
          } else {
            this.trainingQuestionList = res.data.data;
          }
          this.$nextTick(() => {
            window.MathJyeLoad.LayOut(document.body);
          });
        })
        .catch(() => {
          this.pages = this.current;
          // this.loading = false;
        });
    },
    // 设置难度数组
    setDifficultyList() {
      this.difficultyList = [{ value: 0, label: "全部" }].concat(
        this.difficultyList
      );
      // .concat([{ value: 999, label: "自定义" }]);
      // console.log(this.difficultyList);
    },
    typeClick(v, key) {
      this[key] = v.value;
    },
    //显示知识点选择弹出层
    showChangeKnow() {
      this.loreTureArrid = this.knowledgeIds;
      this.newKeys = new Date().getTime();
      this.TreeArray = [];
      this.twoTreeArray = [];
      this.twoTreeVal = "";
      this.twoTree = true;
    },
    //保存知识点
    isOkTree() {
      let loreTureArr = [];
      let arrTureArrid = this.$refs.tree.getCheckedKeys();
      // 去除存在子集的id
      this.loreArr.forEach((i) => {
        arrTureArrid.forEach((item, index) => {
          if (i.number == item && i.child.length > 0) {
            arrTureArrid.splice(index, 1);
          }
        });
      });
      // 判断是否超过5条
      if (arrTureArrid.length > 15) {
        this.$message({
          message: "最多选择15条知识点",
          type: "warning",
        });
        return;
      }
      this.loreArr.forEach((i) => {
        if (arrTureArrid.indexOf(i.number) != -1 && i.child.length == 0) {
          loreTureArr.push(i.name);
        }
      });
      this.knowledgeIds = arrTureArrid;
      this.knowledgeNames = loreTureArr;
      this.twoTree = false;
    },
    delKnowList(i) {
      this.knowledgeIds.splice(i, 1);
      this.knowledgeNames.splice(i, 1);
    },
    searchLore() {
      if (this.twoTreeVal == "") {
        this.TreeArray = [];
        return;
      }
      this.twoTreetype = false;
      let arr = [];
      this.TreeArray = [];
      this.loreArr.forEach((i) => {
        if (i.name.indexOf(this.twoTreeVal) >= 0) {
          arr.push(i.id);
          if (!i.child || (i.child && i.child.length == 0)) {
            this.TreeArray.push(i);
          }
        }
      });
      if (arr.length == 0) {
        this.$message({
          message: "没有找到相似知识点",
          type: "warning",
        });
        this.twoTreetype = true;
        return;
      }
      this.twoTreeArray = arr;
      this.$nextTick(() => {
        this.twoTreetype = true;
      });
    },
    elTreeChange() {
      // console.log(this.$refs.tree.getCheckedKeys());
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
    },
    clickIndexType(v) {
      this.indexVariationBtn = v.val;
    },
    //显示答案组件
    showAwswer(v) {
      if (this.showAwswerList.indexOf(v.id) == -1) {
        this.showAwswerList.push(v.id);
      } else {
        this.showAwswerList.splice(this.showAwswerList.indexOf(v.id), 1);
      }
    },
    // 变式训练题check刷新
    trainingSet() {
      this.trainingQuestionList.map((index) => {
        if (index.xuekeId == this.indexQuestion.xuekeId) {
          index.xuekeType = true;
        } else {
          index.xuekeType = false;
        }
      });
      if (this.indexTypes == "KnowledgePoints") {
        this.knowledgeListData.map((index) => {
          if (index.xuekeId == this.indexQuestion.xuekeId) {
            index.xuekeType = true;
          } else {
            index.xuekeType = false;
          }
        });
      }

      this.boxKey = new Date().getTime();
    },
    //知识点设置当前题未原题（取消原题）
    changeIndexQuestion(v) {
      // console.log(v.xuekeType);
      this.indexListQuestion = v;
      let data = {};
      if (v.xuekeType) {
        data.xuekeId = v.xuekeId;
        data.recommendXuekeId = v.xuekeId;
        data.statusKnowledge = v.id;
        data.isReplace = 1;
      } else {
        data.xuekeId = "";
        data.statusKnowledge = v.id;
        data.isReplace = 0;
      }
      data.statusReplaceKnowledge = 0;
      this.lockTraining(data, 1);
    },
    //搜索
    inherit(type) {
      if (this.inheritVal == "") {
        this.$message({
          message: "搜索内容",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      if (type == 1) {
        getList(this.indexQuestion.examQuestionId, this.inheritVal)
          .then((res) => {
            this.loading = false;
            this.pages = 1;
            this.current = 1;
            this.subBtnType = false;
            this.trainingQuestionList = [];
            this.trainingQuestionList.push(res.data.data);
          })
          .catch(() => {
            this.pages = this.current;
            this.loading = false;
          });
      } else {
        postTrainingList(this.indexQuestion.examQuestionId, {
          quesKeyword: this.inheritVal,
          isNeedExamList: 1,
        })
          .then((res) => {
            this.loading = false;
            this.pages = 1;
            this.current = 1;
            this.subBtnType = false;
            // this.trainingQuestionList = [];
            this.trainingQuestionList = res.data.data;
          })
          .catch(() => {
            this.pages = this.current;
            this.loading = false;
          });
      }
    },
    showEditQ(v) {
      this.$refs.editQuestion.init(v, this.getIndexQuestion);
      // console.log(v);
    },
    // 复制
    copy(id) {
      this.$store.commit("SET_ALL_COPY", { key: id, name: "试题 ID 已复制。" });
    },
    // 设置当前题知识点格式
    setKnowList() {
      if (this.indexQuestion.knowledgeIds) {
        this.indexQuestion.knowledgeIds =
          this.indexQuestion.knowledgeIds.split("#");
        this.indexQuestion.knowledgeNames =
          this.indexQuestion.knowledgeNames.split("#");
      } else {
        this.indexQuestion.knowledgeIds = [];
        this.indexQuestion.knowledgeNames = [];
      }
      this.knowledgeIds = this.indexQuestion.knowledgeIds;
      this.knowledgeNames = this.indexQuestion.knowledgeNames;
    },
    //设置分层考试列表
    setExtendList(data) {
      this.trainingQuestionList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (data.type == 2) {
            index.extendList = index.extendList.filter(
              (i) =>
                i.name != this.getExamPaper.name &&
                i.difficultyType != data.difficultyType
            );
          } else {
            index.extendList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
              difficultyType: data.difficultyType,
            });
          }
          // });
        }
      });
      this.examTraining.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (data.type == 2) {
            index.extendList = index.extendList.filter(
              (i) =>
                i.name != this.getExamPaper.name &&
                i.difficultyType != data.difficultyType
            );
          } else {
            index.extendList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
              difficultyType: data.difficultyType,
            });
          }
          // });
        }
      });
      this.similarQuestionsList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (data.type == 2) {
            index.extendList = index.extendList.filter(
              (i) =>
                i.name != this.getExamPaper.name &&
                i.difficultyType != data.difficultyType
            );
          } else {
            index.extendList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
              difficultyType: data.difficultyType,
            });
          }
          // });
        }
      });
      this.boxKey = new Date().getTime();
    },
    //设置已考试列表
    setList(data, type) {
      //type == 1 就是删除  trainingQuestionList examTraining similarQuestionsList
      this.trainingQuestionList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (type == 1) {
            index.examList = index.examList.filter(
              (i) => i.name != this.getExamPaper.name
            );
          } else {
            index.examList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
            });
          }
          // });
        }
      });
      this.examTraining.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (type == 1) {
            index.examList = index.examList.filter(
              (i) => i.name != this.getExamPaper.name
            );
          } else {
            index.examList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
            });
          }
          // });
        }
      });
      this.similarQuestionsList.map((index) => {
        if (index.id == data.examQuestionOpt) {
          // index.examList.map((item) => {
          if (type == 1) {
            index.examList = index.examList.filter(
              (i) => i.name != this.getExamPaper.name
            );
          } else {
            index.examList.push({
              name: this.getExamPaper.name,
              examDate: "当前考试",
            });
          }
          // });
        }
      });
      this.boxKey = new Date().getTime();
      // console.log(this.examTraining);
      // console.log(this.similarQuestionsList);
      // console.log("222222222222");
    },
    //提交取消变式训练题
    unlockTraining(data) {
      // let data = {};
      data.id = this.indexQuestion.id;
      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      this.btnDisabled = true;
      unlockTraining(data)
        .then(() => {
          this.btnDisabled = false;
          this.$store.commit("delTraining", data);
          this.setList(data, 1);
          // consol
        })
        .catch(() => {
          this.pages = this.current;
          this.btnDisabled = false;
        });
    },
    // 提交新增变式训练题 锁定知识点
    lockTraining(data, type) {
      let onload = false;
      if (!data.id) {
        data.id = this.indexQuestion.id;
      } else {
        onload = true;
      }

      if (this.btnDisabled) {
        this.$message({
          message: "网络状态不佳，请不要快速点击！",
          type: "warning",
        });
        return;
      }
      this.btnDisabled = true;
      lockTraining(data)
        .then((res) => {
          this.btnDisabled = false;
          //type为1就是在锁定知识点
          if (type == 1 && res.data.data.number) {
            data.knowledgeIds = res.data.data.number;
            data.knowledgeNames = res.data.data.name;
            this.$store.commit("changeIdQuestion", data);
          }
          if (!type) {
            this.$store.commit("putTraining", data);
            this.setList(data);
            if (onload) {
              this.onloadView();
            }
          }
          // putTraining
        })
        .catch(() => {
          this.btnDisabled = false;
          if (type == 1) {
            if (data.isReplace == 1) {
              this.setTraningList(1);
            }
            if (data.isReplace == 0) {
              this.setTraningList(0);
            }
          }
        });
    },
    setTraningList(type) {
      if (!this.indexListQuestion) {
        return;
      }
      //1就是取消知识点  其他就是锁定知识点
      if (type == 1) {
        this.trainingQuestionList.map((index) => {
          if (index.id == this.indexListQuestion.id) {
            index.xuekeType = false;
          }
        });
        this.knowledgeListData.map((index) => {
          if (index.id == this.indexListQuestion.id) {
            index.xuekeType = false;
          }
        });
      } else {
        this.trainingQuestionList.map((index) => {
          if (index.id != this.indexListQuestion.id) {
            index.xuekeType = false;
          } else {
            index.xuekeType = true;
          }
        });
        this.knowledgeListData.map((index) => {
          if (index.id != this.indexListQuestion.id) {
            index.xuekeType = false;
          } else {
            index.xuekeType = true;
          }
        });
      }
      //如果当前xuekeid为空 就讲所有设为空
      if (!this.indexQuestion.xuekeId) {
        this.trainingQuestionList.map((index) => {
          index.xuekeType = false;
        });
      }
      // console.log(this.indexQuestion.xuekeId);
      // console.log(this.trainingQuestionList);
      this.boxKey = new Date().getTime();
    },
    //锁定知识点
    addKnow(v) {
      this.indexListQuestion = v;
      let data = {
        recommendXuekeId: v.xuekeId,
        statusKnowledge: v.id,
        isReplace: 2,
        statusReplaceKnowledge: 0,
      };
      if (this.indexQuestion.xuekeId != v.xuekeId) {
        data.xuekeId = "";
      }
      // console.log(data);
      this.lockTraining(data, 1);
    },
    // 取消知识点锁定
    delKnow() {
      let data = {
        recommendXuekeId: "",
        statusKnowledge: 1,
      };
      this.putPaperQuestionObj(data);
    },
    setDataList(data) {
      // console.log(this.current);
      if (this.current == 1) {
        this.trainingQuestionList = data;
        this.oldDataList = [...data];
      } else {
        if (!this.oldDataList || this.oldDataList.length == 0) {
          this.trainingQuestionList = [...data];
        }
        if (!this.oldDataList || this.oldDataList.length == 0) {
          // console.log("进了");
          this.oldDataList = [...data];
        } else {
          if (this.oldDataList[0].id == data[0].id) {
            // console.log(data);
            this.pages = this.current;
          } else {
            // console.log("进入了条件");
            this.oldDataList = [...data];
            this.trainingQuestionList = this.trainingQuestionList.concat(data);
            // console.log(this.trainingQuestionList);
          }
        }
      }
      this.$nextTick(() => {
        window.MathJyeLoad.LayOut(document.body);
      });
    },
    //根据id获取试题
    getQuestion(id) {
      answer(id).then((res) => {
        this.knowledgeListData.push(res.data.data);
        // console.log(res);
      });
    },
    // 变式训练题列表
    trainingList() {
      if (!this.oldQuestionId) {
        return;
      }
      if (this.current == 1) {
        this.loading = true;
      }

      let page = this.current;
      trainingList(this.oldQuestionId, this.current, this.getExamPaper.id)
        .then((res) => {
          this.loading = false;
          // console.log(page);
          if (page == 1) {
            this.current = page;
          }
          this.setDataList(res.data.data);
        })
        .catch(() => {
          this.pages = this.current;
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.model-check {
  .el-checkbox.is-bordered.is-checked {
    border-color: #2474ed;
  }
  .el-checkbox {
    margin: 0 0 6px;
    display: block;
  }
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0;
  }
}
.exam-list-box {
  display: flex;
  justify-content: space-between;
  // padding: 12px;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  // span {

  // }
}
.examQuestionListKn {
  // .question-btn-right {

  // }
  .uploadVariationDialog .uploadVariation .el-upload .el-upload-dragger {
    width: 610px;
    height: 150px;
  }
  .uploadVariationDialog {
    .uploadVariation {
      padding: 20px 0 0 0;
      text-align: center;
      .upload-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .difficulty-input {
    width: 48px;
    height: 21px;
    margin: 4px 8px 3px 0;
    input {
      line-height: 21px;
      height: 21px;
      padding: 0 4px;
      text-align: center;
    }
  }
  .search {
    color: $primary-color;
    border-color: $primary-color;
    line-height: 21px;
    height: 21px;
    padding: 0;
    padding: 0 8px;
    margin: 4px 8px 3px 0;
    &:hover {
      color: #ffffff;
      background-color: $primary-color;
    }
  }
  .hr {
    background: rgba(0, 0, 0, 0.85);
    width: 12px;
    height: 1px;
    border: none;
    margin-top: 13px;
    margin-right: 7px;
  }

  .two-tree-box {
    display: flex;
    justify-content: space-between;
    > div {
      height: 400px;
      overflow-y: scroll;
      width: 50%;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #2474ed;
      }
    }
    .is-checked {
      .el-tree-node__content {
        > .el-tree-node__label {
          color: #2474ed;
        }
      }
    }
  }
  label {
    margin-bottom: 0;
  }
  .search-box {
    .el-input {
      .el-input__inner {
        border: 1px dashed #e0e0e0;
        margin-right: 8px;
        width: 334px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .layered-search-list {
    .unload-btn {
      padding: 5px 15px;
      border-color: $primary-color;
      color: $primary-color;
      position: absolute;
      right: 0;
      top: 40px;
      background-color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: $primary-color;
      }
    }
    .input-box {
      display: flex;
      position: absolute;
      right: 0;
      flex-wrap: wrap;
      .input-up {
        width: 100%;
      }
    }
    .btn-all {
      padding: 5px 15px;
    }
    .el-input {
      width: 334px;
      margin-right: 12px;
      .el-input__inner {
        border: 1px dashed #e0e0e0;
        margin-right: 8px;
        width: 334px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.examQuestionListKn {
  border: 1px solid #e0e0e0;
  padding: 12px 14px;
  margin: 12px 0 40px;
  border-radius: 4px;
  min-height: 500px;
  .search-item-box {
    .search-item {
      margin-top: 8px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 80%;
      .type-list {
        cursor: pointer;
      }
      .search-item-title {
        margin: 4px 8px 3px 0;
        line-height: 21px;
      }
      > span {
        margin-right: 8px;
      }
      .size-btn {
        color: $primary-color;
        margin: 4px 8px 3px 0;
        cursor: pointer;
      }
      .know-list {
        background: #e6e6e4;
        border-radius: 4px;
        padding: 0 8px;
        color: #000000;
        position: relative;
        margin: 4px 8px 3px 0;
        line-height: 21px;
        &:hover {
          i {
            opacity: 1;
          }
        }
        i {
          // el-icon-remove
          opacity: 0;
          color: red;
          position: absolute;
          right: -8px;
          top: -8px;
          cursor: pointer;
        }
      }
      .difficulty-list {
        background-color: unset;
        color: #333333;
        cursor: pointer;
      }

      .on {
        background-color: $primary-color;
        color: #ffffff;
      }
    }
  }
  .layered-search-list {
    position: relative;

    .search-item {
      width: 60%;
    }
  }
  .layered-search {
    margin-top: 8px;
  }
  // .question-list-box {
  //   min-height: 240px;
  // }
  .on-succ.question-list {
    border-color: $primary-color;
    .question-btn-box {
      display: flex;
    }
  }
  .question-list {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
    min-height: 40px;
    &:hover {
      border-color: $primary-color;
      .question-btn-box {
        display: flex;
      }
    }
    .question-btn-box {
      // opacity: 0;
      // visibility: hidden;
      background: #f5f5f5;
      border: 1px solid #e0e0e0;
      padding: 5px 12px;
      width: 100%;
      display: none;
      align-items: center;
      justify-content: space-between;
      .tips {
        display: flex;
        width: 30%;
        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 60%;
          color: $primary-color;
        }
        span {
          margin-left: 12px;
          cursor: pointer;
        }
      }
      .question-btn-right {
        display: flex;
        align-items: center;
        img {
          width: 25px;
          margin-left: 50px;
          cursor: pointer;
        }
        .el-button {
          padding: 4px 8px;
          margin-right: 16px;
          color: $primary-color;
        }

        // justify-content: space-between;
      }
    }
    .show-btn {
      display: flex;
    }
    .question-html {
      padding: 5px 8px;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .unload-btn {
      padding: 5px 15px;
      border-color: $primary-color;
      color: $primary-color;
      position: absolute;
      right: 0;
      top: 40px;
      background-color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: $primary-color;
      }
    }
    .btn-all {
      padding: 5px 15px;
    }
    > div {
      display: flex;
      align-items: center;
    }
    .search-left {
      div {
        padding: 0 8px;
        line-height: 20px;
        border: 1px solid #e0e0e0;
        color: $primary-color;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;
        cursor: pointer;
      }
      .on {
        border-color: $primary-color;
        color: #ffffff;
        background-color: $primary-color;
      }
    }
  }
}
</style>
