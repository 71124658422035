<template>
  <div class="ExamNewLore" :style="{ 'padding-top': getQuestionHeight }">
    <div class="top-header-box">
      <div class="top-style">
        <div class="left-box">
          <div class="span succ-tips" @click="linkTo(1)">1</div>
          <div class="title">裁切试题</div>
          <hr />
          <div
            class="span succ-tips"
            :class="{
              voluntarily: knowledgeErrType,
            }"
          >
            2
          </div>
          <div class="title">标注知识点</div>
          <template v-if="isResMode === 0">
            <hr />
            <div
              class="span"
              :class="{
                'succ-tips': trainingType,
                voluntarily: trainingErrType,
              }"
              @click="linkTo(3)"
            >
              3
            </div>
            <div class="title">匹配变式题</div>
          </template>
          <template v-if="isResMode === 0">
            <hr />
            <div
              class="span"
              :class="{ 'succ-tips': layTrainingType }"
              @click="linkTo(4)"
            >
              4
            </div>
            <div class="title">匹配分层变式题</div>
          </template>
        </div>
        <div class="right-box">
          <el-button
            class="btn-style"
            type="success"
            :loading="btnLoading"
            @click="submit"
            >返回</el-button
          >
          <el-button class="btn-style" type="primary" @click="goLink"
            >完 成</el-button
          >
        </div>
      </div>
    </div>
    <!-- //顶部以及右边的题号 -->
    <examBtnHeader
      :index-types="indexTypes"
      :layered-types="layeredTypes"
      @getNewView="getNewView"
    ></examBtnHeader>
    <div class="left-cnt-box">
      <!-- 试题盒子 -->
      <!-- <div class="tab-box">
        <div
          v-for="(v, i) in tabs"
          :key="i"
          :class="{ on: indexTypes == v.val }"
          @click="clickIndexType(v, 'indexTypes')"
        >
          {{ v.name }}<template v-if="v.number">（{{ v.number }}）</template>
        </div>
      </div> -->
      <!-- 拓展题类型 -->
      <div
        v-if="indexTypes == 'layeredVariantTraining'"
        class="tab-box layerd-box"
      >
        <div
          v-for="(v, i) in layeredList"
          :key="i"
          :class="{ on: layeredTypes == v.val }"
          @click="clickIndexType(v, 'layeredTypes')"
        >
          {{ v.name }}<template v-if="v.number">（{{ v.number }}）</template>
        </div>
      </div>
      <!-- 试题列表 -->
      <questionList
        :index-types="indexTypes"
        :layered-types="layeredTypes"
        @getNewView="getNewView"
      ></questionList>
    </div>
  </div>
</template>

<script>
const tabs = [
  {
    name: "标注知识点",
    val: "KnowledgePoints",
  },
  {
    name: "匹配变式训练题",
    val: "VariantTraining",
    number: 0,
  },
  {
    name: "匹配分层变式训练题",
    val: "layeredVariantTraining",
  },
];
const layeredList = [
  {
    name: "较易拓展题",
    val: "simpleQuestions",
    number: 0,
  },
  {
    name: "中等拓展题",
    val: "commonlyQuestions",
    number: 0,
  },
  {
    name: "较难拓展题",
    val: "difficultQuestions",
    number: 0,
  },
];
// 服务端各接口需要判断是否有 考试管理相关权限 或 资源中心个人试卷相关权限
import { fetchList, getallsubjectquestiontype } from "@/core/api/exam/examLore";
import {
  setExamPaperStatusStep,
  getExamQuestionTypeByLevelAndSubject,
} from "@/core/api/exam/exampaper";
import { mapGetters } from "vuex";
import examBtnHeader from "./component/examBtnHeader.vue";
import questionList from "./component/examQuestionList.vue";
import { STEP_FINISHALL } from "@/core/util/constdata";
import { filterSubject } from "@/core/util/util";
export default {
  name: "ExamNewLore",
  components: {
    examBtnHeader,
    questionList,
  },
  data() {
    return {
      tabs: tabs,
      layeredList: layeredList,
      btnLoading: false,
      //  knowledType variantType layeredType
      knowledType: false,
      variantType: false,
      layeredType: false,
      indexTypes: "KnowledgePoints",
      layeredTypes: "simpleQuestions",
      options: [],
      checked: false,
      value: "",
      isResMode: 0,
      indexQuestion: {},
      trainingType: false,
      knowledgeType: false,
      knowledgeErrType: false,
      trainingErrType: false,
      layTrainingType: false,
    };
  },

  computed: {
    ...mapGetters(["getQuestionHeight", "getExamQuestion", "getIndexQuestion"]),
  },
  watch: {
    getIndexQuestion() {
      this.indexQuestion = Object.assign(this.getIndexQuestion);
      this.setVariation();
    },
    // 监听当前题在试题列表中是否更新了数据
    getExamQuestion: {
      deep: true,
      handler() {
        this.questionListData = [...this.getExamQuestion];
        this.questionListData.forEach((index) => {
          if (this.indexQuestion.examQuestionId == index.examQuestionId) {
            this.indexQuestion = index;
            this.setVariation();
          }
        });
        this.knowledgeErrType = false;
        this.trainingErrType = false;
        this.knowledgeType = false;
        this.trainingType = false;
        let indexArr = this.questionListData.filter((item) => item.isPush == 1);

        let knowledgeArr = indexArr.filter(
          (item) => item.statusReplaceKnowledge == 1
        );
        let trainingArr = indexArr.filter(
          (item) => item.statusReplaceTraining == 1
        );
        // 判断是不是没有人工标注知识点
        if (knowledgeArr.length == indexArr.length && indexArr.length > 0) {
          this.knowledgeErrType = true;
        }
        // 判断是不是没有人工标注变式题
        if (trainingArr.length == indexArr.length && indexArr.length > 0) {
          this.trainingErrType = true;
        }
        // 获取分层变式题是否已标注
        let index = 0;
        // 变式题
        let indexVar = 0;
        indexArr.map((item) => {
          let arr = item.examQuestionExtendList.filter(
            (item) => item.type == 2
          );
          let vaArr = item.examQuestionExtendList.filter(
            (item) => item.type == 1
          );

          if (arr.length > 0) {
            index++;
          }
          if (vaArr.length > 0) {
            indexVar++;
          }
          if (item.isPush == 1) {
            // 是否标注了知识点
            if (item.statusKnowledge == 1) {
              this.knowledgeType = true;
            }
          }
        });
        if (indexVar == indexArr.length) {
          this.trainingType = true;
        } else {
          this.trainingType = false;
        }

        if (index == indexArr.length) {
          this.layTrainingType = true;
        } else {
          this.layTrainingType = false;
        }
      },
    },
  },
  created() {
    if (this.$route.name === "resexamlore") {
      this.isResMode = 1;
    }
    if (this.$route.query.type) {
      // console.log(this.$route.query.type);
      this.indexTypes = this.tabs[this.$route.query.type - 1].val;
    }
    this.examList();
  },
  methods: {
    getallsubjectquestiontype() {
      let data = {
        level: this.level,
      };
      getallsubjectquestiontype(data).then((res) => {
        // console.log(res);
        this.$store.commit("SET_QUESTION_TYPE_LIST", res.data.data);
        this.$store.commit("SET_SUBJECT_LIST", filterSubject(this.level));
        this.$store.commit("SET_SUBJECT_ID", this.subjectId);
      });
    },
    //变式训练题请款获取当前试题有几道变式训练题
    setVariation() {
      let index = 0;
      for (let i = 1; i < 6; i++) {
        let str = "examQuestionOpt" + i;
        if (this.getIndexQuestion[str]) {
          index++;
        }
      }
      this.tabs[1].number = index;

      // layeredList.  commonly difficult
      let simple = 0;
      let commonly = 0;
      let difficult = 0;
      this.getIndexQuestion.examQuestionExtendList.forEach((index) => {
        if (index.difficultyType == 1) {
          difficult++;
        }
        if (index.difficultyType == 2) {
          commonly++;
        }
        if (index.difficultyType == 3) {
          simple++;
        }
      });
      this.layeredList[0].number = simple;
      this.layeredList[1].number = commonly;
      this.layeredList[2].number = difficult;
    },
    linkTo(step) {
      if (step === 1) {
        // 裁切试题
        // if (this.statusReplace === 1) {
        //   this.$message.error("已有试题电子化，不能重新切题！");
        //   return;
        // }
        let filePath = this.examPaper.filePath;
        let filePathArr = filePath.split(",");
        if (filePathArr[0].endsWith(".docx")) {
          // Word 文件
          if (this.isResMode === 1) {
            this.$router.push({
              name: "resexampaperdivide",
              query: {
                id: this.$route.query.id,
                res: 1,
              },
            });
          } else {
            this.$router.push({
              // name: "exampaperdivide",
              name: "ExamNewPaperDivide",
              query: this.$route.query,
            });
          }
        } else if (filePathArr[0].endsWith(".pdf")) {
          // PDF 文件
          if (this.isResMode === 1) {
            this.$router.push({
              name: "resexampaperpicdivide",
              query: this.$route.query,
            });
          } else {
            this.$router.push({
              name: "examNewPaperCropper",
              query: this.$route.query,
            });
          }
        } else {
          // 图片文件
          if (this.isResMode === 1) {
            this.$router.push({
              name: "resexampaperpicdivide",
              query: this.$route.query,
            });
          } else {
            this.$router.push({
              name: "examNewPaperCropper",
              query: this.$route.query,
            });
          }
        }
      } else if (step === 3) {
        // 锁定变式题
        // this.$message.error("不能直接进行后面的步骤！");
        this.$router.push({
          name: "examvariation",
          query: this.$route.query,
        });
      } else if (step === 4) {
        this.$router.push({
          name: "ExamLayeredVariant",
          query: this.$route.query,
        });
      }
    },
    getNewView() {
      this.examList();
    },
    clickIndexType(v, name) {
      this[name] = v.val;
      this.setVariation();
    },
    examList() {
      let data = {
        examPaperId: this.$route.query.id,
      };
      fetchList(data).then((res) => {
        this.level = res.data.data.examPaper.level;
        this.subjectId = res.data.data.examPaper.subjectId;
        this.examId = res.data.data.examPaper.examId;
        this.examPaper = res.data.data.examPaper;
        if (res.data.data.examQuestionVOList.length === 0) {
          this.$message.error("当前试卷还未切题！");
          this.loading = false;
          return;
        }
        let str = "examQuestionOpt";
        let data = res.data.data;
        data.examQuestionVOList.map((index) => {
          //判断下如果没有 人为操作过 就默认把变式训练题清除了
          if (index.statusTraining == 0) {
            // console.log("进了");
            for (let i = 1; i < 6; i++) {
              let newStr = str + i;
              index[newStr] = 0;
            }
          }
          // console.log(index);
        });
        // console.log(data.examQuestionVOList);

        //存储获取到的所有数据
        this.$store.commit("setExamPaper", data);
        // 存储第一道题
        this.$store.commit("setIndexQuestion", data.examQuestionVOList[0]);
        this.questionListData = data.examQuestionVOList;
        let knowledgeArr = this.questionListData.filter(
          (item) => item.statusReplaceKnowledge
        );
        let trainingArr = this.questionListData.filter(
          (item) => item.statusReplaceTraining
        );
        if (knowledgeArr.length > 0) {
          this.knowledgeType = true;
        }
        if (trainingArr.length > 0) {
          this.trainingType = true;
        }
        //获取题型列表
        this.getallsubjectquestiontype();
        this.getTypeList();
      });
    },
    getTypeList() {
      let data = new URLSearchParams();
      data.append("level", this.level);
      data.append("subjectId", this.subjectId);
      getExamQuestionTypeByLevelAndSubject(data).then((res) => {
        // this.typeList = res.data.data.resQuestionTypes;
        let typeList = [];
        res.data.data.resQuestionTypes.forEach((index) => {
          typeList.push({
            value: index.id,
            label: index.name,
          });
        });
        this.$store.commit("setExamTypeList", typeList);
      });
    },
    submit() {
      let query = {
        id: this.examId,
        subId: this.subjectId,
        ifVirtual: this.$route.query.ifVirtual,
      };
      this.$router.push({
        name: "exampaper",
        query: query,
      });
    },
    goLink() {
      if (
        this.questionListData.filter(
          (i) => !i.knowledgeNames || !i.statusKnowledge
        ).length > 0
      ) {
        this.$message({
          message: "请先标注所有知识点",
          type: "warning",
        });
        return;
      }
      if (this.isResMode === 1) {
        this.$router.push({
          name: "myResource",
        });
      } else {
        if (this.indexTypes == "KnowledgePoints") {
          let query = {
            id: this.examId,
            subId: this.subjectId,
            ifVirtual: this.$route.query.ifVirtual,
          };
          this.$router.push({
            name: "exampaper",
            query: query,
          });
          return;
        }
        if (this.indexTypes == "VariantTraining") {
          let type = false;
          this.questionListData.forEach((i) => {
            if (i.examQuestionOpt1 == 0) {
              type = true;
            }
          });
          if (type == true) {
            this.$message({
              message: "请锁定所有变式题",
              type: "warning",
            });
            return;
          }
          if (this.examPaper.statusStep < STEP_FINISHALL) {
            let data = {
              examPaperId: this.$route.query.id,
              statusStep: STEP_FINISHALL,
            };
            setExamPaperStatusStep(data);
          }
          let query = {
            id: this.examId,
            subId: this.subjectId,
          };
          this.$router.push({
            name: "exampaper",
            query: query,
          });
          return;
        }
        if (this.indexTypes == "layeredVariantTraining") {
          let query = {
            id: this.examId,
            subId: this.subjectId,
          };
          this.$router.push({
            name: "exampaper",
            query: query,
          });
          return;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ExamNewLore {
  min-width: 1220px;
  width: 80vw;
  margin: 0 auto;
  padding-top: 120px;
  .left-cnt-box {
    width: calc(100% - 175px);
    .tab-box {
      display: flex;
      div {
        margin-right: 8px;
        width: 222px;
        height: 30px;
        text-align: center;
        line-height: 28px;
        border: 1px solid #e0e0e0;
        color: #333333;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
      }
      .on {
        border-color: $primary-color;
        color: #ffffff;
        background-color: $primary-color;
      }
    }
    .layerd-box {
      margin-top: 12px;
      div {
        color: rgba(0, 0, 0, 0.5);
        border: 1px dashed #e0e0e0;
      }
      .on {
        border-color: #409eff;
        background-color: #409eff;
      }
    }
    .question-html-box {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      overflow: hidden;
      padding: 12px;
    }
  }
  .top-header-box {
    width: 100%;
    position: fixed;
    left: 0;
    top: 74px;
    z-index: 99;
    padding: 16px 0;
    // box-shadow: inset 0 -1px 0 0 #dddddd;
    background-color: #ffffff;
    padding-left: calc(100vw - 100%);
  }
  .top-style {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1220px;
    width: 80vw;
    margin: 0 auto;

    .right-box {
      display: flex;
      align-items: center;

      span {
        color: #000000;
        margin-left: 8px;
      }

      .btn-style {
        margin-left: 36px;
        background-color: #2474ed;
        border-color: #2474ed;
      }
    }

    .left-box {
      display: flex;
      align-items: center;

      .span {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        margin-right: 8px;
        background-color: #e0e0e0;
        border-radius: 50%;
        color: #ffffff;
        cursor: pointer;
      }

      .succ-tips {
        background-color: #2474ed;
      }
      .voluntarily {
        background-color: #f57574 !important;
      }

      .title {
        font-size: 14px;
        font-weight: 500;
      }

      hr {
        margin: 0 16px;
        width: 80px;
        height: 1px;
        background-color: #e0e0e0;
      }
    }
  }
}
</style>
