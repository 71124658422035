<template>
  <div :key="indexKey" class="awser-all-box">
    <div
      v-if="
        indexQuexstion.knowledgeNames &&
        indexQuexstion.knowledgeNames.length > 0
      "
      class="analysis-item"
    >
      【知识点】
      <div class="know-list">
        <div v-for="(v, i) in indexQuexstion.knowledgeNames" :key="i">
          {{ v }}
        </div>
      </div>
    </div>
    <div
      v-if="indexQuexstion.answer && indexQuexstion.answer.length > 0"
      class="analysis-item"
    >
      【答案】
      <div v-html="indexQuexstion.answer"></div>
    </div>
    <div
      v-if="indexQuexstion.analysis && indexQuexstion.analysis.length > 0"
      class="analysis-item"
    >
      【解答】
      <div v-html="indexQuexstion.analysis"></div>
    </div>
    <div
      v-if="indexQuexstion.analyse && indexQuexstion.analyse.length > 0"
      class="analysis-item"
    >
      【分析】
      <div v-html="indexQuexstion.analyse"></div>
    </div>
    <div
      v-if="indexQuexstion.ability && indexQuexstion.ability.length > 0"
      class="analysis-item"
    >
      【点评】
      <div v-html="indexQuexstion.ability"></div>
    </div>
  </div>
</template>

<script>
import { answer } from "@/core/api/resource/knowledgePointsPaper";
export default {
  name: "Awser",
  props: {
    id: Number,
  },
  data() {
    return {
      indexQuexstion: {},
      indexKey: 1,
    };
  },
  created() {
    this.answer();
  },
  methods: {
    answer() {
      answer(this.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          this.indexQuexstion.answer = res.data.data.answer;
          this.indexQuexstion.analyse = res.data.data.analyse;
          this.indexQuexstion.analysis = res.data.data.analysis;
          this.indexQuexstion.ability = res.data.data.ability;
          this.indexQuexstion.knowledgeNames =
            res.data.data.knowledgeNames.split("#");
          if (res.data.data.id != this.id) {
            this.indexQuexstion.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            this.indexQuexstion.newId = res.data.data.id;
            this.indexQuexstion.newXuekeId = res.data.data.xuekeId;
          }
          this.indexKey = new Date().getTime();
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.awser-all-box {
  .know-list {
    display: flex;
    div {
      margin-right: 24px;
    }
  }
  > div {
    padding: 6px 12px;
  }
}
</style>
