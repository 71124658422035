<template>
  <div class="examBtnHeader">
    <div class="question-btn-list">
      <div class="question-btn-box">
        <div ref="flexdL" class="left-box">
          <div class="left-box-header">
            <div>
              原题显示：<el-checkbox v-model="showQuestion"></el-checkbox>
            </div>
            <div>
              难度：
              <el-select
                v-model="indexQuestion.difficulty"
                placeholder="请选择"
                @change="changeDiffculty"
              >
                <el-option
                  v-for="item in difficultyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              科目：
              <el-select
                v-model="indexQuestion.subjectId"
                placeholder="请选择"
                @change="changeSubject"
              >
                <el-option
                  v-for="item in GET_SUBJECT_Q_LIST"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              题型：
              <el-select
                v-model="indexQuestion.type"
                placeholder="请选择"
                @change="changeQuestionType"
              >
                <el-option
                  v-for="item in examTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="know-list-box">
              <div class="know-left">
                <span> 知识点： </span>

                <div
                  v-for="(v, i) in indexQuestion.knowledgeNames"
                  :key="i"
                  class="konw-list"
                >
                  <i class="el-icon-remove" @click="delKnow(i)"></i>
                  {{ v }}
                </div>
                <span class="size-btn" @click="showChangeKnow">
                  <i class="el-icon-plus"></i>添加
                </span>
              </div>

              <span class="size-btn" @click="goNextQuestion()">
                下一题 <i class="el-icon-d-arrow-right"></i>
              </span>
            </div>
          </div>
          <div>
            <span style="margin-right: 20px">{{ getExamPaper.name }}</span>
            <span
              >科目：{{
                getExamPaper.subjectId | setName(subjectOptions)
              }}</span
            >
          </div>
          <div
            v-if="showQuestion && !indexQuestion.questionBak"
            class="question-box-img-style"
            :class="{ 'top-question-scroll': scrollBox }"
            v-html="indexQuestion.question"
          ></div>
          <div
            v-if="showQuestion && indexQuestion.questionBak"
            class="question-box-img-style"
            :class="{ 'top-question-scroll': scrollBox }"
            v-html="indexQuestion.questionBak"
          ></div>
        </div>
        <!-- 知识点列表 -->
        <div v-if="indexTypes == 'KnowledgePoints'" class="right-box">
          <el-button type="text" class="clear-all" @click="clearKnowledge()"
            >清空知识点</el-button
          >
          <!-- <div class="tips-list">当前试题</div> -->
          <div class="tips-list four-tips">未标知识点</div>
          <div class="tips-list two-tips">已标知识点</div>
          <div class="tips-list three-tips">标记为原题</div>
          <div class="tips-list voluntarily-tips">系统标注</div>
          <div class="list-type">
            仅显示未标记：<el-checkbox v-model="isSign"></el-checkbox>
          </div>
          <div class="order-list-box">
            <template v-for="(v, i) in getExamQuestion">
              <div
                v-if="
                  (isSign && !v.knowledgeNames) || !v.statusKnowledge || !isSign
                "
                :key="i"
                class="order-list"
                :class="{
                  on: v.id == indexQuestion.id,
                  err: !v.knowledgeNames || !v.statusKnowledge,
                  succ: v.knowledgeNames && v.statusKnowledge,
                  succOn: v.xuekeId,
                  voluntarily: v.statusReplaceKnowledge,
                }"
                @click="questionListClick(v)"
              >
                {{ v.examQuestionOrder }}
              </div>
            </template>
          </div>
        </div>
        <!-- 变式训练题 -->
        <div v-if="indexTypes == 'VariantTraining'" class="right-box">
          <!-- <div class="tips-list">当前试题</div> -->
          <div class="tips-list four-tips">未锁定变式题</div>
          <div class="tips-list two-tips">已锁定变式题</div>
          <div class="tips-list voluntarily-tips">系统标注</div>
          <!-- <div class="tips-list three-tips">标记为原题</div> -->
          <div class="list-type">
            仅显示未锁定：<el-checkbox v-model="isSign"></el-checkbox>
          </div>
          <div class="order-list-box">
            <template v-for="(v, i) in getVariationList">
              <!-- {{ v }} -->
              <div
                v-if="(isSign && v.type == false) || !isSign"
                :key="i"
                class="order-list"
                :class="{
                  on: v.id == indexQuestion.id,
                  err: !v.type,
                  succ: v.type,
                  voluntarily: v.statusReplaceTraining,
                }"
                @click="questionVariation(v)"
              >
                {{ v.examQuestionOrder }}
              </div>
            </template>
          </div>
        </div>
        <!-- 分层变式训练题 -->
        <div v-if="indexTypes == 'layeredVariantTraining'" class="right-box">
          <!-- <div class="tips-list">当前试题</div> -->
          <div class="tips-list four-tips">未标记</div>
          <div class="tips-list two-tips">已标记</div>
          <!-- <div class="tips-list three-tips">标记为原题</div> -->
          <div class="list-type">
            仅显示未标记：<el-checkbox v-model="isSign"></el-checkbox>
          </div>
          <div :key="listkey" class="order-list-box">
            <template v-for="(v, i) in getExamQuestion">
              <!-- {{ setType(v) }} -->
              <div
                v-if="
                  (isSign && v.examQuestionExtendList == 0) ||
                  v.examQuestionExtendList.length == 0 ||
                  !isSign
                "
                :key="i"
                class="order-list"
                :class="{
                  on: v.id == indexQuestion.id,
                  err: setType(v, 1),
                  succ: setType(v, 2),
                }"
                @click="questionListClick(v)"
              >
                {{ v.examQuestionOrder }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- 二叉树的弹出层 -->
    <el-dialog
      v-dialogDrag
      title="批量选择知识点"
      :visible.sync="twoTree"
      width="50%"
      :modal="false"
    >
      <div class="two-tree-box">
        <div>
          <el-tree
            v-if="twoTreetype"
            ref="tree"
            :key="newKeys"
            :data="knowledgeAllList"
            show-checkbox
            node-key="number"
            :default-checked-keys="loreTureArrid"
            :default-expanded-keys="twoTreeArray"
            :props="defaultProps"
            @check="elTreeChange"
          >
          </el-tree>
        </div>
        <div style="width: 300px">
          <el-input
            v-model="twoTreeVal"
            placeholder="请输入内容"
            class="input-with-select"
            @input="searchLore"
          >
            <el-button
              slot="append"
              class="search-btn"
              icon="el-icon-search"
              @click="searchLore"
            ></el-button>
          </el-input>
          <el-checkbox-group
            v-model="loreTureArrid"
            style="width: 200px; margin-top: 20px"
          >
            <template v-for="(v, i) in TreeArray">
              <el-checkbox :key="i" :label="v.number">{{ v.name }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="twoTree = false">取 消</el-button>
        <el-button class="btn-all" type="primary" @click="isOkTree"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getQuestionPoint,
  putObj,
  clearKnowledge,
} from "@/core/api/exam/examLore";
import { subjectOptions, difficultyList } from "@/core/util/constdata";
import { filterDifficulty } from "@/core/util/util";
import { mapGetters } from "vuex";

export default {
  name: "ExamBtnHeader",
  props: {
    indexTypes: {
      type: String,
      default: "",
    },
    layeredTypes: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      subjectOptions: subjectOptions,
      difficultyList: difficultyList,
      indexQuestion: {},
      showQuestion: true,
      checked: true,
      value: "",
      options: [],
      examTypeList: [],
      subjectId: "",
      knowledgeAllList: [],
      scrollBox: false,
      isSign: false,
      // 知识点弹出层 start
      twoTree: false,
      twoTreetype: true,
      defaultProps: {
        children: "child",
        label: "name",
      },
      loreTureArrid: [],
      TreeArray: [],
      twoTreeArray: [],
      newKeys: 1,
      twoTreeVal: "",
      listkey: 1,
      //知识点弹出层 end
    };
  },
  computed: {
    ...mapGetters([
      "getExamPaper",
      "getIndexQuestion",
      "getExamQuestion",
      "getExamTypeList",
      "getVariationList",
      "GET_QUESTION_TYPE_LIST",
      "GET_SUBJECT_Q_LIST",
      "GET_SUBJECT_ID",
    ]),
  },
  watch: {
    indexTypes() {
      // console.log(this.indexTypes);
      // console.log(this.getVariationList);
    },
    layeredTypes() {
      this.setLayeredTypes();
      // console.log(this.indexTypes);
      // console.log(this.getVariationList);
    },
    getExamQuestion: {
      deep: true,
      handler() {
        this.getExamQuestion.forEach((index) => {
          if (this.indexQuestion.examQuestionId == index.examQuestionId) {
            this.indexQuestion = Object.assign({}, index);
            this.indexQuestion.difficulty = filterDifficulty(
              this.indexQuestion.difficulty
            );

            this.setKnowList();
          }
        });
      },
    },
    GET_QUESTION_TYPE_LIST: {
      deep: true,
      handler() {
        if (this.indexQuestion.subjectId) {
          this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
            (item) => item.subjectId == this.indexQuestion.subjectId
          );
        }
      },
    },
    getIndexQuestion: {
      immediate: true,
      handler() {
        let oldQ = JSON.parse(JSON.stringify(this.indexQuestion));
        this.indexQuestion = Object.assign({}, this.getIndexQuestion);
        this.indexQuestion.difficulty = filterDifficulty(
          this.indexQuestion.difficulty
        );
        if (this.GET_QUESTION_TYPE_LIST.length > 0) {
          this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
            (item) => item.subjectId == this.indexQuestion.subjectId
          );
        }
        //拆分知识点
        this.setKnowList();
        if (oldQ.subjectId != this.indexQuestion.subjectId) {
          this.getToList();
        }
        this.$nextTick(() => {
          var jzjxImg = document.getElementsByClassName("jzjxImg");
          let jzjxImgArr = Array.from(jzjxImg);
          let maxWidth =
            this.removePxToNumber(getComputedStyle(this.$refs.flexdL).width) -
            30;

          let ratio = 1,
            max = maxWidth;

          if (jzjxImgArr.length) {
            if (jzjxImgArr[0].style.maxWidth) {
              max = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
            }
            let width = this.removePxToNumber(jzjxImgArr[0].style.width);
            if (maxWidth < width) {
              maxWidth = width;
            }
            ratio = maxWidth / max;
          }
          jzjxImgArr.forEach((item) => {
            const width = this.removePxToNumber(item.style.width);
            // console.log(ratio);
            item.style.width = width * ratio + "px";
            item.style.display = "block";
          });
        });
      },
    },
  },
  created() {
    // console.log(this.indexTypes);
    // this.getToList();
    this.setLayeredTypes();
    if (this.indexQuestion.subjectId) {
      // this.subjectId = this.GET_SUBJECT_ID;
      this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
        (item) => item.subjectId == this.GET_SUBJECT_ID
      );
    }
  },
  mounted() {
    let height = this.$refs.flexdL.offsetHeight;
    if (height > 250) {
      height = height + 100;
    }
    this.$store.commit("setQuestionHeight", height);
    //给window添加一个滚动滚动监听事件
    window.addEventListener("scroll", this.handleScroll);
  },
  updated() {
    setTimeout(() => {
      let height = this.$refs.flexdL.offsetHeight;
      if (height > 250) {
        height = height + 100;
      }
      // console.log(height);
      this.$store.commit("setQuestionHeight", height);
    }, 100);
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    removePxToNumber(val) {
      let data = val.replace("px", "");
      return Number(data);
    },
    changeSubject() {
      // console.log();
      this.getToList();
      this.examTypeList = this.GET_QUESTION_TYPE_LIST.filter(
        (item) => item.subjectId == this.indexQuestion.subjectId
      );
      if (this.examTypeList.length > 0) {
        this.indexQuestion.type = this.examTypeList[0].id;
        this.changeQuestionType();
      } else {
        this.indexQuestion.type = "";
      }
      this.$store.commit("SET_SUBJECT_ID", this.indexQuestion.subjectId);
    },
    // 清空知识点
    clearKnowledge() {
      let data = {
        examPaperId: this.getIndexQuestion.examPaperId,
      };
      this.$confirm("此操作将清空所有试题知识点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clearKnowledge(data).then(() => {
            this.$emit("getNewView");
            this.$message({
              type: "success",
              message: "清空成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delKnow(i) {
      // console.log(i);
      // this.indexQuestion.knowledgeIds.splice(i, 1);
      // this.indexQuestion.knowledgeNames.splice(i, 1);
      let copyData = Object.assign({}, this.indexQuestion);
      copyData.knowledgeIds.splice(i, 1);
      copyData.knowledgeNames.splice(i, 1);
      let data = {
        knowledgeIds: copyData.knowledgeIds.join("#"),
        knowledgeNames: copyData.knowledgeNames.join("#"),
      };
      this.saveQuestion(data);
      // this.$store.commit("changeIdQuestion", data);
    },
    setLayeredTypes() {
      if (this.layeredTypes == "difficultQuestions") {
        this.layeredVaTypes = 1;
      }
      if (this.layeredTypes == "commonlyQuestions") {
        this.layeredVaTypes = 2;
      }
      if (this.layeredTypes == "simpleQuestions") {
        this.layeredVaTypes = 3;
      }
      this.listkey = new Date().getTime();
      // console.log(this.layeredTypes);
    },
    setType(v, checkType) {
      // console.log(v);
      let type = false;
      let arr = v.examQuestionExtendList.filter(
        (i) => i.difficultyType == this.layeredVaTypes
      );
      // console.log(arr);
      if (checkType == 1) {
        if (arr.length == 0) {
          type = true;
        }
      } else {
        if (arr.length > 0) {
          type = true;
        }
      }
      return type;
    },
    //切换
    goNextQuestion() {
      this.$store.commit("nextQuestion", this.indexQuestion.examQuestionId);
    },
    //修改题型
    changeQuestionType() {
      let data = {
        type: this.indexQuestion.type,
        subjectId: this.indexQuestion.subjectId,
        knowledgeIds: "",
        knowledgeNames: "",
      };
      this.saveQuestion(data, "twoTree");
    },
    //修改难度
    changeDiffculty() {
      let data = {
        difficulty: this.indexQuestion.difficulty,
      };
      this.saveQuestion(data, "twoTree");
    },
    // 将知识点拆分为数组
    setKnowList() {
      if (this.indexQuestion.knowledgeIds) {
        this.indexQuestion.knowledgeIds =
          this.indexQuestion.knowledgeIds.split("#");
        this.indexQuestion.knowledgeNames =
          this.indexQuestion.knowledgeNames.split("#");
      } else {
        this.indexQuestion.knowledgeIds = [];
        this.indexQuestion.knowledgeNames = [];
      }
    },
    //显示知识点选择弹出层
    showChangeKnow() {
      this.loreTureArrid = this.indexQuestion.knowledgeIds;
      this.newKeys = new Date().getTime();
      this.TreeArray = [];
      this.twoTreeArray = [];
      this.twoTreeVal = "";
      this.twoTree = true;
    },
    //保存知识点
    isOkTree() {
      let loreTureArr = [];
      let arrTureArrid = this.$refs.tree.getCheckedKeys();
      // 去除存在子集的id
      this.loreArr.forEach((i) => {
        arrTureArrid.forEach((item, index) => {
          if (i.number == item && i.child.length > 0) {
            arrTureArrid.splice(index, 1);
          }
        });
      });
      // 判断是否超过5条
      if (arrTureArrid.length > 5) {
        this.$message({
          message: "最多保存5条知识点",
          type: "warning",
        });
        return;
      }
      this.loreArr.forEach((i) => {
        if (arrTureArrid.indexOf(i.number) != -1 && i.child.length == 0) {
          loreTureArr.push(i.name);
        }
      });
      this.loreTureArrid = arrTureArrid;
      this.loreTureArr = loreTureArr;
      let data = {
        knowledgeIds: this.loreTureArrid.join("#"),
        knowledgeNames: this.loreTureArr.join("#"),
      };
      this.saveQuestion(data, "twoTree");
    },

    saveQuestion(data, modelType) {
      data.statusKnowledge = 1;
      data.id = this.indexQuestion.examQuestionId;
      putObj(data).then(() => {
        this.$store.commit("changeQuestionId", data);
        //如果存在第二个值  就说明有弹框  直接关闭
        if (modelType) {
          this[modelType] = false;
        }
      });
    },
    // 知识点弹出层搜索
    searchLore() {
      if (this.twoTreeVal == "") {
        this.TreeArray = [];
        return;
      }
      this.twoTreetype = false;
      let arr = [];
      this.TreeArray = [];
      this.loreArr.forEach((i) => {
        if (i.name.indexOf(this.twoTreeVal) >= 0) {
          arr.push(i.id);
          if (!i.child || (i.child && i.child.length == 0)) {
            this.TreeArray.push(i);
          }
        }
      });
      if (arr.length == 0) {
        this.$message({
          message: "没有找到相似知识点",
          type: "warning",
        });
        this.twoTreetype = true;
        return;
      }
      this.twoTreeArray = arr;
      this.$nextTick(() => {
        this.twoTreetype = true;
      });
    },
    elTreeChange() {
      // console.log(this.$refs.tree.getCheckedKeys());
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
    },
    //组装知识点的树形结构
    getToList() {
      this.knowledgeAllList = [];
      getQuestionPoint({
        level: this.getExamPaper.level,
        subjectId: this.indexQuestion.subjectId,
      }).then((res) => {
        this.loreArr = res.data.data.resQuestionPoints;
        let arrList = this.loreArr;
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.knowledgeAllList.push(obj);
          });
        this.$store.commit("setKnowList", {
          paperKnowList: this.loreArr,
          paperTreeKnowList: this.knowledgeAllList,
        });
      });
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      // console.log(result);
      return result;
    },
    handleScroll() {
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }

      if (scrollTop > 10) {
        this.scrollBox = true;
        this.setheight = scrollTop + "px";
        let height = this.$refs.flexdL.offsetHeight;
        if (height > 250) {
          height = height + 100;
        }
        // console.log(height);
        this.$store.commit("setQuestionHeight", height);
      } else {
        this.scrollBox = false;
      }
    },
    questionListClick(v) {
      // 存储第一道题
      // console.log(v);
      this.$store.commit("setIndexQuestion", v);
    },
    questionVariation(v) {
      this.$store.commit("setQuestionVariation", v);
    },
  },
};
</script>
<style lang="scss">
.examBtnHeader {
  .two-tree-box {
    display: flex;
    justify-content: space-between;
    > div {
      height: 400px;
      overflow-y: scroll;
      width: 50%;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: #2474ed;
      }
    }
    .is-checked {
      .el-tree-node__content {
        > .el-tree-node__label {
          color: #2474ed;
        }
      }
    }
  }
  .el-select-dropdown__item.selected {
    color: $primary-color;
  }
  .question-btn-box {
    .left-box {
      .el-select {
        width: 74px;
        .el-input__inner {
          height: 18px;
          line-height: 18px;
          border: none;
          padding: 0;
          color: $primary-color;
        }
        i {
          height: 18px;
          line-height: 18px;
          color: $primary-color;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.examBtnHeader {
  .question-btn-list {
    // border: 1px solid #e0e0e0;
    position: fixed;
    width: 100%;
    left: 0;
    top: 126px;
    z-index: 99;
    border-radius: 4px;
    margin: 10px 0;
    // padding-left: calc(100vw - 100%);
    .question-btn-box {
      min-width: 1220px;
      width: 80vw;
      height: 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .size-btn {
        color: $primary-color;
        margin-left: 12px;
        cursor: pointer;
      }
      .know-list-box {
        display: flex;
        flex-wrap: wrap;
        min-width: 321px;
        width: 50%;
        align-items: center;
        justify-content: space-between;
        line-height: 20px;
        .know-left {
          display: flex;
          align-items: center;
          width: calc(100% - 80px);
          flex-wrap: wrap;
          > span {
            margin-bottom: 3px;
          }
          > div {
            margin-left: 8px;
          }
        }

        .konw-list {
          background: rgb(0 0 0 / 10%);
          border-radius: 4px;
          padding: 0 8px;
          color: #000000;
          position: relative;
          margin: 0 8px 3px;
          &:hover {
            i {
              opacity: 1;
            }
          }
          i {
            // el-icon-remove
            opacity: 0;
            color: red;
            position: absolute;
            right: -8px;
            top: -8px;
            cursor: pointer;
          }
        }
      }
      .left-box,
      .right-box {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        overflow: hidden;
        padding: 10px 15px 7px;
      }
      .right-box {
        width: 160px;
        background-color: #ffffff;
        padding: 10px 0 7px;
        .clear-all {
          display: block;
          margin: 0 auto 10px;
        }
        .order-list-box {
          max-height: 50vh;
          min-height: 10vh;
          overflow-y: scroll;
          padding: 0 15px;
          &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
          }

          ///*<!--修改 滑块 -->*/
          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.25);
          }
          div {
            padding: 0 6px;
            border-radius: 4px;
            // overflow: hidden;
            width: 100%;
            border: 1px solid #e0e0e0;
            text-align: center;
            margin-top: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 20px;
            cursor: pointer;
            font-size: 12px;
          }
          .err {
            border-color: #e0e0e0;
            // background-color: #fc5743;
          }
          .succ {
            background: #2474ed;
            border: 1px solid #2474ed;
            color: #ffffff;

            &:hover {
              background: #55a2f7;
              border: 1px solid #55a2f7;
            }
          }
          .succOn {
            border: 1px solid #81d86b;
            color: #ffffff;
            background: #81d86b;
          }
          .voluntarily {
            background: #f57574;
            color: #ffffff;
          }
          .on.succ {
            background: #55a2f7;
            border: 1px solid #55a2f7;
          }
          .on.err {
            border: 1px solid #2474ed;
            color: #2474ed;
            background-color: #f3faff;
          }
          .on.succOn {
            border: 1px solid #a4e293;
            color: #ffffff;
            background-color: #a4e293;
          }
        }
        .list-type {
          // padding-bottom: 5px;
          border-bottom: 1px solid #e0e0e0;
          margin: 0 15px;
        }
        .tips-list {
          color: rgba(0, 0, 0, 0.5);
          position: relative;
          padding-left: 18px;
          margin: 0 15px 10px;
          &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 5px;
            width: 10px;
            height: 10px;
            background: #fc5743;
            border: 1px solid #fc5743;
            overflow: hidden;
            border-radius: 4px;
            color: #000;
          }
        }
        .two-tips {
          &::after {
            background: #2474ed;
            border: 1px solid #2474ed;
          }
          // &:hover {
          //   &::after {
          //     background: #55a2f7;
          //     border: 1px solid #55a2f7;
          //   }
          // }
        }
        .voluntarily-tips {
          &::after {
            background: #ff9c9e;
            border: 1px solid #ff9c9e;
          }
          // &:hover {
          //   &::after {
          //     background: #ff9c9e;
          //     border: 1px solid #ff9c9e;
          //   }
          // }
        }
        .three-tips {
          &::after {
            background: #87d068;
            border: 1px solid #87d068;
          }
          // &:hover {
          //   &::after {
          //     background: #a4e293;
          //     border: 1px solid #a4e293;
          //   }
          // }
        }
        .four-tips {
          &::after {
            background: #ffffff;
            border: 1px solid #e0e0e0;
          }
          // &:hover {
          //   &::after {
          //     color: #2474ed;
          //     border: 1px solid #2474ed;
          //   }
          // }
          // #2474ED
        }
        // margin-left: 20px;
      }
      .left-box {
        // width: 988px;
        margin-right: 12px;
        background-color: #ffffff;

        width: calc(100% - 175px);
        // max-height: 450px;
        .top-question-box {
          // overflow-y: scroll;
        }
        .top-question-scroll {
          max-height: 200px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
          }

          ///*<!--修改 滑块 -->*/
          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.25);
          }
        }
        .left-box-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        > div {
          margin-right: 10px;
          img {
            max-width: 100%;
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .el-checkbox {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
